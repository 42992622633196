import { useTranslation } from 'react-i18next';
import {
  AddressForm,
  Body,
  ContactForm,
  ForeignAddressForm,
  Form,
  GermanAddressForm,
  useApplicationHelpers,
  useExtendedReducer,
  Heading as HeadingT
} from 'lkh-portal-ui-library';
import Heading from 'components/Heading';
import { useHealthInsuranceContext } from 'contexts/HealthInsuranceContext';
import { WizardSlot } from 'layout/wizard/slots';
import { applicationField, partnerField } from 'models';
import { InsuredPersonForm } from 'pages/DigitalOrderPage/components/InsuredPersonForm';
import { PersonRoleEnum } from 'models/extension-generated';
import { BankDetailsForm } from 'pages/DigitalOrderPage/components/BankDetailsForm';
import { getIbanInfo } from 'utils/bankDetails';
import TooltipInfo from 'components/TooltipInfo';
import { NavigationSection } from 'pages/DigitalOrderPage/components/NavigationSection/NavigationSection';
import { useSearchParams } from 'react-router-dom';
import { LegalFooter } from 'components/LegalFooter';

const { Input } = Form.Components;

const getKeys = (id: string) => ({
  // Personal Data
  genderKey: partnerField(id, 'gender'),
  salutationKey: partnerField(id, 'salutation'),
  titleKey: partnerField(id, 'title'),
  firstNameKey: partnerField(id, 'firstname'),
  lastNameKey: partnerField(id, 'lastname'),
  birthDateKey: partnerField(id, 'birthDate'),
  fundsFromGZKey: partnerField(id, 'applicationInformation.fundsFromGZ'),
  employmentTypeKey: partnerField(id, 'applicationInformation.employmentGroup'),
  // Marital status
  maritalStatusKey: partnerField(id, 'maritalStatus'),
  // GermanAddressForm
  livingInGermanyKey: partnerField(id, 'livingInGermany'),
  // AddressForm
  streetKey: partnerField(id, 'permanentResidence.street'),
  houseNumberKey: partnerField(id, 'permanentResidence.houseNumber'),
  postalCodeKey: partnerField(id, 'permanentResidence.postalCode'),
  cityKey: partnerField(id, 'permanentResidence.city'),
  countryKey: partnerField(id, 'permanentResidence.country'),
  // ForeignAddressForm
  hasForeignResidenceKey: partnerField(id, 'hasLivedInForeignCountry'),
  foreignCountryKey: partnerField(id, 'foreignCountry'),
  // Contact
  phoneKey: partnerField(id, 'permanentResidence.phone'),
  emailKey: partnerField(id, 'permanentResidence.email'),
  // Application information
  applicationStartKey: applicationField('applicationStart'),
  insuranceNumberLKHKey: partnerField(id, 'applicationInformation.insuranceNumberLKH'),
  agentIdKey: applicationField('agentId'),
  subAgentIdKey: applicationField('subAgentId'),
  // Bank details
  ibanKey: partnerField(id, 'bankDetails.iban'),
  bankNameKey: partnerField(id, 'bankDetails.bankName'),
  bicNumberKey: partnerField(id, 'bankDetails.bicNumber'),
  hasPaymentAuthorizationKey: partnerField(id, 'bankDetails.hasPaymentAuthorization')
});

export const InsuranceHolderStep = () => {
  const { t } = useTranslation('insuranceHolderStep');
  const [searchParams] = useSearchParams();

  const { state, reducer, dispatch } = useHealthInsuranceContext();
  const { getSinglePartnerByRole, isHolderInsured } = useApplicationHelpers(state.model);
  const { setUniqueRole, clearRole, createPartner } = useExtendedReducer(dispatch);

  const agentId = searchParams.get('vermittlerId')?.toLowerCase();
  const holder = getSinglePartnerByRole(PersonRoleEnum.POLICY_HOLDER);
  const isInsured = isHolderInsured(holder);
  const holderId = holder?.id || 'undefined';
  const payerId = getSinglePartnerByRole(PersonRoleEnum.PAYMENT_CONTRIBUTOR)?.id || 'undefined';
  const holderKeys = getKeys(holderId);
  const payerKeys = getKeys(payerId);

  return (
    <WizardSlot.Main>
      <div className="mx-[8px] s:mx-[80px]">
        <div className="bg-white s:px-m s:py-[32px]">
          <HeadingT className="text-secondary-600 text-center s:text-left m-[0px] py-m" bold>
            {t('section.policyholder.title')}
          </HeadingT>
        </div>
        <Form state={state} dispatch={dispatch}>
          <div className="layout-res">
            <InsuredPersonForm
              hasBirthDate
              partnerId={holderId}
              partnerKeys={holderKeys}
              reducer={reducer}
              hasTitle
              hasMaritalStatus
              text={{
                firstName: {
                  label: t('personalInfo.firstName.label')
                },
                lastName: {
                  label: t('personalInfo.lastName.label')
                }
              }}
              disabled={isInsured}
            />
            <div className="grid-res" />

            <div className="grid-mx-res">
              <Heading level={3}>{t('addressForm.section.title')}</Heading>
            </div>
            <AddressForm
              streetKey={holderKeys.streetKey}
              houseNumberKey={holderKeys.houseNumberKey}
              postalCodeKey={holderKeys.postalCodeKey}
              cityKey={holderKeys.cityKey}
              label={{
                title: undefined,
                street: {
                  label: t('addressForm.fields.street.label')
                },
                houseNumber: {
                  label: t('addressForm.fields.houseNumber.label')
                },
                postalCode: {
                  label: t('addressForm.fields.postalCode.label')
                },
                city: {
                  label: t('addressForm.fields.city.label')
                }
              }}
              disabled={isInsured}
              AddressComponent={
                <GermanAddressForm
                  livingInGermanyKey={holderKeys.livingInGermanyKey}
                  label={t('addressForm.fields.livingInGermany.label')}
                  disabled={isInsured}
                />
              }
            />
            <ForeignAddressForm
              hasForeignResidenceKey={holderKeys.hasForeignResidenceKey}
              foreignCountryKey={holderKeys.foreignCountryKey}
              state={state}
              label={{
                hasForeignResidence: t('foreignAddressForm.hasForeignResidence.label'),
                country: {
                  label: t('foreignAddressForm.country.label')
                }
              }}
              disabled={isInsured}
            />
            <div className="grid-res" />

            <div className="grid-mx-res">
              <Heading level={3}>{t('contact.title')}</Heading>
            </div>
            <ContactForm
              phoneKey={holderKeys.phoneKey}
              emailKey={holderKeys.emailKey}
              text={{
                title: undefined,
                email: {
                  label: t('contact.fields.email.label')
                },
                phone: {
                  label: t('contact.fields.phone.label')
                }
              }}
            />
            <div className="grid-res" />

            <div className="grid-mx-res">
              <Heading level={3}>{t('insurance.title')}</Heading>
            </div>
            <div className="grid-res-2">
              <Input
                componentKey={holderKeys.insuranceNumberLKHKey}
                label={t('insurance.number.label')}
                type="number"
              />
            </div>
            <div className="grid-res" />
            <div className="grid-mx-res">
              <div className="flex justify-start items-center space-x-2">
                <div>
                  <Heading level={3}>{t('agent.title')}</Heading>
                </div>
                <div>
                  <TooltipInfo text={<Body regular>{t('agent.tooltip')}</Body>} />
                </div>
              </div>
            </div>
            <div className="grid-res-2">
              <div>
                <Input
                  disabled={!!agentId}
                  componentKey={holderKeys.agentIdKey}
                  label={t('agent.number.label')}
                />
              </div>
              <div>
                <Input componentKey={holderKeys.subAgentIdKey} label={t('agent.subNumber.label')} />
              </div>
            </div>
          </div>

          <div className="grid-my-res">
            <Heading level={3}>{t('section.payer.title')}</Heading>
          </div>
          <div className="layout-res grid-my-res">
            <BankDetailsForm
              holderId={holderId}
              payerId={payerId}
              payerKeys={payerKeys}
              reducer={reducer}
              setUniqueRole={setUniqueRole}
              clearRole={clearRole}
              createPartner={createPartner}
              getIbanInfo={getIbanInfo}
            />
          </div>
          <div className="text-[12px] font-[400] leading-[18px] tracking-normal text-lkh-text-info">
            {t('common:fieldRequired')}
          </div>
        </Form>
      </div>
      <NavigationSection />
      <LegalFooter />
    </WizardSlot.Main>
  );
};
