import classNames from 'classnames';
import { AlertIcon, Icon, Label, Tooltip } from 'lkh-portal-ui-library';
import { useHealthInsuranceContext } from 'contexts/HealthInsuranceContext';

import styles from './ValidationError.module.scss';

export const ValidationError = () => {
  const { state } = useHealthInsuranceContext();

  if (state.validationResults.size > 0) {
    const getErrorList = () => {
      const listItems = Array.from(state.validationResults).map(([key, value]) => (
        <li key={key}>{!value?.success && value?.error?.issues[0].message}</li>
      ));
      return (
        <Label medium>
          <ul className={classNames('m-0', styles.listOfErrors)}>{listItems}</ul>
        </Label>
      );
    };

    const getFirstError = () => {
      if (!state.validationResults.entries().next().value[1].success) {
        return state.validationResults.entries().next().value[1].error.issues[0].message;
      } else {
        return null;
      }
    };

    return (
      <Tooltip
        children={
          <div className={styles.validationError}>
            <Label medium className={styles.textContainer}>
              <span className={styles.firstError}>{getFirstError()}</span>
              <span>
                {state.validationResults.size > 1 && ` + ${state.validationResults.size - 1}`}
              </span>
            </Label>
            <Icon icon={<AlertIcon />} />
          </div>
        }
        tooltipContent={getErrorList()}
        showArrow
        tooltipDebounce={300}
        tooltipOffset={1}
      />
    );
  }

  return <></>;
};
