import { useEffect, useState } from 'react';
import { NeedSituationEnum, Tariff, TariffService } from 'models/extension-generated';
import { useHealthInsuranceContext } from 'contexts/HealthInsuranceContext';

type UseTariffQuestionReturnType = {
  isLoading: boolean;
  tariffs: Array<Tariff>;
};

export const useTariffQuery = (partnerId?: string): UseTariffQuestionReturnType => {
  const [isLoading, setIsLoading] = useState(false);
  const [tariffs, setTariffs] = useState<Array<Tariff>>([]);

  const { state } = useHealthInsuranceContext();

  // TODO possibly add DoB to applicationStart
  const availableStartDate = state.model.applicationStart;

  // Make search query on filters change
  useEffect(() => {
    const fetchTariffQuestionsFn = async () => {
      setIsLoading(true);

      try {
        // TODO check if tariff config is required
        const response = await TariffService.getPublicTariffsByFilters({
          requestBody: {
            needSituation: NeedSituationEnum.ZVGKV
          }
        });
        setTariffs(response);
      } catch (error) {
        setTariffs([]);
      } finally {
        setIsLoading(false);
      }
    };

    availableStartDate && fetchTariffQuestionsFn();
  }, [availableStartDate]);

  return {
    isLoading,
    tariffs
  };
};
