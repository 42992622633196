import { Heading, Icon } from 'lkh-portal-ui-library';
import styles from './NoHealthQuestions.module.scss';
import { useTranslation } from 'react-i18next';
import { NoQuestionsPictogram } from './NoHealthQuestionsPictogram';

export const NoHealthQuestions = () => {
  const { t } = useTranslation('wizardHealth');
  return (
    <section className={`flex flex-col items-center text-center ${styles.section}`}>
      <Icon className="mb-s" color="lkh-color-primary-80" icon={<NoQuestionsPictogram />} />
      <Heading secondary regular className="text-logo-500">
        {t('section.noQuestions.message')}
      </Heading>
    </section>
  );
};
