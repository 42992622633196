import classNames from 'classnames';

import {
  // Button,
  Caption,
  Chip,
  Label,
  Tooltip
} from 'lkh-portal-ui-library';
import { Tariff, TariffCalculationResult } from 'models/extension-generated';

import { TariffDescription } from '../../TariffDescription';
import { formatPrice } from 'utils/string';

type TariffRowProps = {
  tariff: Tariff;
  calculation?: TariffCalculationResult;
  tariffsList: Array<Tariff>;
  isMobile: boolean;
};

export const TariffRow = ({ tariff, calculation, tariffsList, isMobile }: TariffRowProps) => {
  const getTariffDescription = (id: string) => {
    const rawDescription = tariffsList.find((tariff) => tariff.id === id)?.description;
    return <TariffDescription description={rawDescription!} />;
  };

  function hasRiskPremium(calculation?: TariffCalculationResult): boolean {
    const risk = calculation?.calculationMonthly?.riskSurcharges;
    if (risk && risk > 0) {
      return true;
    }
    return false;
  }
  const tariffPrice = calculation?.calculationMonthly?.amount;

  return (
    <div className="flex justify-between">
      <Tooltip
        children={
          <Chip className="bg-highlight text-primary">
            <Label className="text-darkGreen" bold>
              {tariff.id}
            </Label>
          </Chip>
        }
        tooltipContent={getTariffDescription(tariff.id)}
        showArrow
        tooltipDebounce={300}
        tooltipOffset={7}
        placement="left"
      />

      <div className="flex items-center">
        {hasRiskPremium(calculation) && (
          <Label regular className={classNames('p-r-8 m-r-8 text-neutral-500')}>
            {formatPrice(calculation?.calculationMonthly?.riskSurcharges)}
          </Label>
        )}
        <Caption medium className={`m-0 ${isMobile ? 'text-white' : 'text-black'}`}>
          {tariffPrice && formatPrice(calculation?.calculationMonthly?.amount)}
          {!tariffPrice && '-,--'}
        </Caption>
      </div>
    </div>
  );
};
