import { Dialog, DialogSize, PrimaryButton, TertiaryButton } from 'lkh-portal-ui-library';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import styles from './InsuranceHolderDialog.module.scss';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import classNames from 'classnames';

type InsuranceHolderDialogProps = {
  isOpen: boolean;
  data: {
    originalPolicyholder: string;
    newPolicyholder: string;
  };
  handleConfirmDialog: () => void;
  onClose: () => void;
};

export const InsuranceHolderDialog = ({
  isOpen,
  data,
  onClose,
  handleConfirmDialog
}: InsuranceHolderDialogProps) => {
  const { t } = useTranslation('wizardRelationship');
  const handleCloseDialog = () => onClose();

  const originalPolicyholderParagraph = t('insuranceHolderDialog.description.paragraph1').replace(
    'ORIGINAL_POLICY_HOLDER',
    data.originalPolicyholder
  );
  const newPolicyholderParagraph = t('insuranceHolderDialog.description.paragraph2').replace(
    'NEW_POLICY_HOLDER',
    data.newPolicyholder
  );

  return (
    <Dialog
      className="absolute top-[20vh]"
      dialogMainClassName={classNames(styles.mainModal, 'w-[343px] s:w-[540px]')}
      size={DialogSize.Custom}
      open={isOpen}
      onClose={handleCloseDialog}
      header={
        <div className="flex-row items-center justify-between space-x-4">
          <InfoOutlinedIcon className="text-warning-70" />
          <div className="text-[14px] font-[700] leading-[20px]">
            {t('insuranceHolderDialog.header')}
          </div>
        </div>
      }
      closeIcon={<CloseIcon className="text-lkh-text-info" />}
    >
      <div className="flex justify-start mt-[12px] ml-[0px] s:ml-[40px]">
        <div className="text-[12px] font-[400] leading-[16px] text-lkh-text-info">
          {originalPolicyholderParagraph} <br />
          {newPolicyholderParagraph}
        </div>
      </div>
      <div className="w-full flex justify-end items-center gap-[16px] mt-[32px]">
        <TertiaryButton
          className="text-[14px] font-[700] leading-[20px]"
          children={t('insuranceHolderDialog.cancelButton')}
          onClick={handleCloseDialog}
        />
        <PrimaryButton
          className="text-[14px] font-[700] leading-[20px]"
          children={t('insuranceHolderDialog.acceptButton')}
          onClick={handleConfirmDialog}
        />
      </div>
    </Dialog>
  );
};
