/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Marital status (Familienstand)
 */
export enum MaritalStatusEnum {
    SINGLE = 'SINGLE',
    MARRIED = 'MARRIED',
    PARTNERED = 'PARTNERED',
    WIDOWED = 'WIDOWED',
}
