import React from 'react';

interface HeadingProps {
  level: number;
  children: React.ReactNode;
}

/*
Examples:
<Heading level={1}>This is an H1</Heading>
<Heading level={2}>This is an H2</Heading>
<Heading level={3}>This is an H3</Heading>
*/
export const Heading = ({ level, children }: HeadingProps) => {
  let headingStyle = '';

  switch (level) {
    case 1: // LKH Text/Headline1/bold
      headingStyle =
        'text-secondary-600 tracking-normal text-[25px] s:text-[28px] font-[700] leading-[36px]';
      break;
    case 2: // LKH Text/Headline3/bold
      headingStyle = 'text-secondary-600 tracking-normal text-[20px] font-[700] leading-[28px]';
      break;
    case 3: // LKH Text/Text large/bold
      headingStyle = 'text-secondary-600 tracking-normal text-[16px] font-[700] leading-[24px]';
      break;
  }

  const HeadingTag = `h${level}` as keyof JSX.IntrinsicElements;

  return <HeadingTag className={headingStyle}>{children}</HeadingTag>;
};
