/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * relation of this person to the insurance (policy) holder
 */
export enum RelationshipToHolderEnum {
    PARTNER = 'PARTNER',
    CHILDREN = 'CHILDREN',
    GRAND_CHILDREN = 'GRAND_CHILDREN',
    NONE = 'NONE',
}
