/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum InSignStatusEnum {
    INCOMPLETE = 'incomplete',
    COMPLETE = 'complete',
    EXTERN = 'extern',
    SIGNED = 'signed',
    REJECTED = 'rejected',
    EXTERN_DECLINED = 'externDeclinedDsgvo',
    EXTERN_EXPIRED = 'externExpired',
    NOT_STARTED = 'notStarted',
}
