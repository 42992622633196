import { useTranslation } from 'react-i18next';

import { Partner, PersonRoleEnum } from '../../../../models/extension-generated';
import { PartnerFormReducers, PartnerIdProp } from 'lkh-portal-ui-library/dist/models';
import { Body, Checkbox, Chip, ChipType, Form } from 'lkh-portal-ui-library';
import {
  ClearRolePayload,
  CreatePartnerPayload,
  SetUniqueRolePayload
} from 'lkh-portal-ui-library/dist/components/Form/reducer/partner';
import { relationshipToHolderOptions } from 'utils/dropdowns';
import Heading from 'components/Heading';
import { useRef, useState } from 'react';
import { InsuranceHolderDialog } from '../InsuranceHolderDialog';
import TooltipInfo from 'components/TooltipInfo';
import classNames from 'classnames';

const { Dropdown } = Form.Components;

const isUserTitleEmpty = (title: string) => !!title.replace(/\s|,/g, '');

export type InsuranceHolderRelationFormProps = PartnerIdProp & {
  reducer: Pick<PartnerFormReducers, 'updateValue'>;
  relationshipKey: string;
  title?: string;
  disabled?: boolean;
  formatPartnerName: (id: string) => string;
  setUniqueRole: (payload: SetUniqueRolePayload) => void;
  clearRole: (payload: ClearRolePayload) => void;
  createPartner: (payload: CreatePartnerPayload) => void;
  getPartnersByRole: (role: PersonRoleEnum) => Array<Partner>;
  isHolderInsured: (partner: Partner) => boolean;
  isHolderAlsoPayer: () => boolean;
};

export const InsuranceHolderRelationForm = ({
  partnerId,
  reducer: { updateValue },
  relationshipKey,
  title = '',
  formatPartnerName,
  setUniqueRole,
  clearRole,
  createPartner,
  getPartnersByRole,
  isHolderInsured,
  isHolderAlsoPayer
}: InsuranceHolderRelationFormProps) => {
  const { t } = useTranslation('wizardRelationship');
  const checkboxRef = useRef<HTMLInputElement>(null);
  const [isDialogOpen, setDialogOpen] = useState(false);

  const holder = getPartnersByRole(PersonRoleEnum.POLICY_HOLDER)[0];
  const isSameAsCurrent = holder.id === partnerId;

  const originalPolicyholder = holder.id ? formatPartnerName(holder.id) : '';
  const newPolicyholder = formatPartnerName(partnerId);

  const definedPolicyholder = isUserTitleEmpty(originalPolicyholder)
    ? originalPolicyholder
    : t('info.notSelected');

  const label = isSameAsCurrent
    ? t('info.definedPolicyholder.label')
    : t('info.undefinedPolicyholder.label');

  const { hasError } = Form.hooks.useFormComponent(relationshipKey);

  function handleIsSameToggle(isSameAsGivenPartner: boolean): void {
    if (isSameAsGivenPartner) {
      const isInsuredButNotSameAsCurrent = isHolderInsured(holder) && !isSameAsCurrent;

      if (isInsuredButNotSameAsCurrent) {
        setDialogOpen(true);
      } else {
        setUniqueRole({ partnerId, role: PersonRoleEnum.POLICY_HOLDER });
        updateValue({ key: relationshipKey, value: undefined });
      }
    } else {
      clearRole({ role: PersonRoleEnum.POLICY_HOLDER });
      createPartner({ role: PersonRoleEnum.POLICY_HOLDER });
    }

    // special case: previous holder was also a payment contributor: needs to unset it
    if (isHolderAlsoPayer()) {
      clearRole({ role: PersonRoleEnum.PAYMENT_CONTRIBUTOR });
      createPartner({ role: PersonRoleEnum.PAYMENT_CONTRIBUTOR });
    }
  }

  function handleConfirmDialog(): void {
    setUniqueRole({ partnerId, role: PersonRoleEnum.POLICY_HOLDER });
    updateValue({ key: relationshipKey, value: undefined });
    setDialogOpen(false);
  }

  function handleCloseDialog(): void {
    checkboxRef?.current?.click();
    setUniqueRole({ partnerId: holder.id!, role: PersonRoleEnum.POLICY_HOLDER });
    setDialogOpen(false);
  }

  const checkboxIsError = !isSameAsCurrent && hasError;

  return (
    <>
      {isDialogOpen && (
        <InsuranceHolderDialog
          isOpen={isDialogOpen}
          data={{
            originalPolicyholder,
            newPolicyholder
          }}
          onClose={handleCloseDialog}
          handleConfirmDialog={handleConfirmDialog}
        />
      )}

      <div className="grid-mx-res">
        <Heading level={3}>{title}</Heading>
      </div>
      <div className="grid-res-2">
        <div className="flex justify-start items-center space-x-2">
          <div className={classNames({ 'text-error': checkboxIsError })}>
            <Checkbox
              ref={checkboxRef}
              checked={isSameAsCurrent}
              description={t('fields.sameAsInsured.label')}
              onChange={handleIsSameToggle}
              error={checkboxIsError}
            />
          </div>
          <div>
            <TooltipInfo
              text={
                <>
                  <Body medium>{t('fields.sameAsInsured.tooltip.title')}</Body> <br />
                  <br />
                  <Body regular>{t('fields.sameAsInsured.tooltip.description')}</Body>
                </>
              }
            />
          </div>
        </div>
      </div>
      <div className="grid-res-2">
        <Chip color="neutral-100" type={ChipType.Pill}>
          <div className="text-lkh-text-80 text-[12px] font-[400] leading-[16px]">
            {label} <b>{definedPolicyholder}</b>
          </div>
        </Chip>
      </div>

      <div className="grid-res">
        <Body regular>{t('fields.relationship.title')}</Body>
      </div>
      <div className="grid-res-2">
        <Dropdown
          componentKey={relationshipKey}
          options={relationshipToHolderOptions}
          label={t('fields.relationship.label')}
          placeholder={t('fields.relationship.placeholder')}
          disabled={isSameAsCurrent}
        />
      </div>
    </>
  );
};
