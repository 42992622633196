import {
  TabGroup,
  TariffCard,
  Form,
  DropdownOption,
  Loader,
  useExtendedReducer,
  LoaderType,
  formModelGet,
  Tooltip,
  Body,
  InformationCircleIcon,
  useApplicationHelpers
} from 'lkh-portal-ui-library';
import Markdown from 'react-markdown';
import flatMap from 'lodash/flatMap';
import intersection from 'lodash/intersection';
import styles from './index.module.scss';
import { useMemo } from 'react';
import { IconRight } from './Icons/IconRight';
import { IconLeft } from './Icons/IconLeft';
import { useTranslation } from 'react-i18next';
import { useHealthInsuranceContext } from 'contexts/HealthInsuranceContext';
import { PersonRoleEnum, Tariff, TariffSectionEnum } from 'models/extension-generated';
import { TabType, useTariffContext } from 'contexts/TariffSectionContext';
import classNames from 'classnames';
import { partnerField } from 'models';
import { ReactNode } from 'react';
import { getInsuranceStartDates } from 'utils/getInsuranceStartDates';

const { YesNo, Input, Dropdown } = Form.Components;

export type TariffSectionProps = {
  partnerKeys: {
    birthDateKey: string;
    applicationStartKey: string;
  };
};

export const TariffSection = ({ partnerKeys }: TariffSectionProps) => {
  const { isLoading, tariffCards, setTab, tab } = useTariffContext();
  const { t } = useTranslation('tariffSecion');
  const {
    dispatch,
    partnerId,
    state,
    reducer: { updateValue }
  } = useHealthInsuranceContext();
  const { giveTariff, removeTariffs } = useExtendedReducer(dispatch);
  const { getSinglePartnerByRole } = useApplicationHelpers(state.model);
  const holder = getSinglePartnerByRole(PersonRoleEnum.POLICY_HOLDER);

  const availableStartDates: Array<DropdownOption> = useMemo(getInsuranceStartDates, []);
  const currentCards = tariffCards.cards
    ?.filter((card) => card.section === tab)
    .sort((a, b) => (a.orderNo || 0) - (b.orderNo || 0));

  const selectedTariffsKey = partnerField(
    partnerId,
    `applicationInformation.tariffInformation.selectedTariffs`
  );
  const selectedTariffs: Array<Tariff> = formModelGet(state.model, selectedTariffsKey) || [];

  const sectionTariffs = flatMap(
    (tariffCards.cards?.filter((card) => card.section === tab) || []).map(
      (card) => card.tariffs || []
    )
  ).map((tariff) => {
    return tariff;
  });

  const cardSelection = intersection(
    selectedTariffs.map(({ id }) => id),
    sectionTariffs
  )[0];

  const handleTabChange = (id: string | number) => {
    setTab(id as TabType);
  };

  const handleTariffChange = (val: string | undefined) => {
    if (val) {
      // add tariff to the list, but keep tariff from the other section
      const remainingTariffsFromSection: Array<Partial<Tariff>> = sectionTariffs
        .filter((tariff) => tariff !== val)
        .map((tariff) => {
          return { id: tariff, section: tab };
        });

      removeTariffs({ partnerId, tariffs: remainingTariffsFromSection });
      giveTariff({ partnerId, tariff: { id: val, section: tab } });
    } else {
      const allTariffsFromSection = sectionTariffs.map((tariff) => {
        return { id: tariff };
      });

      removeTariffs({ partnerId, tariffs: allTariffsFromSection });
    }

    // reset dependant values
    const hasDownloadedContractInformationDocumentPath = partnerField(
      holder.id || '',
      'applicationInformation.hasDownloadedContractInformationDocument'
    );

    updateValue({
      key: hasDownloadedContractInformationDocumentPath,
      value: undefined
    });
  };

  const renderQuestionTitle = ({
    text,
    tooltipContent
  }: {
    text: string;
    tooltipContent: ReactNode;
  }) => {
    return (
      <>
        <Body small regular>
          {text}
        </Body>
        <Tooltip
          placement="bottom-start"
          tooltipContent={<div className={styles.tooltipWrap}>{tooltipContent}</div>}
          tooltipOffset={5}
        >
          <span className={styles.infoIcon}>
            <InformationCircleIcon />
          </span>
        </Tooltip>
      </>
    );
  };

  return (
    <div className={styles.tarrifSectionWrap}>
      <div className={`${styles.tabGroup}, overflow-auto`}>
        <TabGroup
          selected={tab}
          showDownloadIcon={false}
          list={[
            {
              id: TariffSectionEnum.STATIONAER,
              counter: 100,
              label: t('tariffSection:additionalInsurance')
            },
            {
              id: TariffSectionEnum.ZAHN,
              counter: 50,
              label: t('tariffSection:dentalInsurance')
            }
          ]}
          onTabChange={handleTabChange}
        />
      </div>

      <div className={styles.subHeader}>
        <span>
          {tab === TariffSectionEnum.ZAHN ? (
            <span onClick={() => setTab(TariffSectionEnum.STATIONAER)}>
              <IconLeft />
            </span>
          ) : null}
        </span>
        <span>
          {tab === TariffSectionEnum.STATIONAER
            ? t('tariffSection:additionalInsuranceNote')
            : t('tariffSection:dentalInsuranceNote')}
        </span>
        <span>
          {tab === TariffSectionEnum.STATIONAER ? (
            <span onClick={() => setTab(TariffSectionEnum.ZAHN)}>
              <IconRight />
            </span>
          ) : null}
        </span>
      </div>

      <div className={styles.tariffInputs}>
        <Input
          type="date"
          componentKey={partnerKeys.birthDateKey}
          label={t('tariffSection:dayOfBirth')}
          debounceTime={500}
        />

        <Dropdown
          componentKey={partnerKeys.applicationStartKey}
          options={availableStartDates}
          label={t('tariffSection:startOfInsurance')}
          placeholder={t('tariffSection:choose')}
        />
      </div>

      <div className={styles.tariffNote}>
        {tab === TariffSectionEnum.STATIONAER
          ? t('tariffSection:twoTariffs')
          : t('tariffSection:threeTariffs')}
      </div>
      <div className={classNames('s:flex', styles.tariffSection)}>
        {isLoading ? (
          <Loader type={LoaderType.Circular} />
        ) : (
          currentCards?.map((card) => (
            <div
              className={classNames('w-12/12 s:w-6/12 l:w-4/12', styles.tariffCardsWrapper)}
              key={card.tariffName}
            >
              <TariffCard
                banner={card.banner}
                image={`/images/${card.image}.jpg`}
                tariffName={card.tariffName}
                defaultPrice={card.defaultPrice}
                listOfHighlights={card.listOfHighlights}
                infoChip={card.infoChip}
                otherHighLights={card.otherHighlights}
                landingPageUrl={card.landingPageUrl}
                stablePremiumVisibility={card.stablePremiumVisibility}
                selected={cardSelection}
                stablePremiumText={card.stablePremiumText}
                tariffs={card.tariffs || ['']}
                onChange={(val: any) => handleTariffChange(val)}
                tariffS={card.tariffs?.find((t) => t.toLowerCase().includes('_s')) || ''}
                tariffL={card.tariffs?.find((t) => t.toLowerCase().includes('_l')) || ''}
                shouldShowBanner={currentCards.some((c) => c.banner)}
              />
            </div>
          ))
        )}
      </div>

      <div className="mt-m">
        {tab === TariffSectionEnum.STATIONAER ? (
          <>
            {renderQuestionTitle({
              text: t('tariffSection:hasNationalInsurance'),
              tooltipContent: <Markdown>{t('tariffSection:hasNationalInsuranceTooltip')}</Markdown>
            })}
            <YesNo
              componentKey={partnerField(partnerId, 'applicationInformation.hasNationalInsurance')}
              className="mt-[12px] mb-[24px]"
            />
            {renderQuestionTitle({
              text: t('tariffSection:hasSupplementaryHospitalInsurance'),
              tooltipContent: (
                <Markdown>{t('tariffSection:hasSupplementaryHospitalInsuranceTooltip')}</Markdown>
              )
            })}
            <YesNo
              componentKey={partnerField(
                partnerId,
                'applicationInformation.hasSupplementaryHospitalInsurance'
              )}
              className="mt-[12px] mb-[24px]"
            />
          </>
        ) : (
          <>
            {renderQuestionTitle({
              text: t('tariffSection:hasStatutoryInsurance'),
              tooltipContent: <Markdown>{t('tariffSection:hasStatutoryInsuranceTooltip')}</Markdown>
            })}
            <YesNo
              componentKey={partnerField(partnerId, 'applicationInformation.hasStatutoryInsurance')}
              className="mt-[12px] mb-[24px]"
            />
          </>
        )}
      </div>
    </div>
  );
};
