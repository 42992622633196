import { useHealthInsuranceContext } from 'contexts/HealthInsuranceContext';
import { TabType, useTariffContext } from 'contexts/TariffSectionContext';
import { applicationField } from 'models';
import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useURLParams = () => {
  const didRun = useRef(false);
  const { reducer, partnerId } = useHealthInsuranceContext();
  const { isLoading, tariffCards, setTab } = useTariffContext();
  const [searchParams] = useSearchParams();
  const tariffId = searchParams.get('tarif')?.toLowerCase();
  const agentId = searchParams.get('vermittlerId')?.toLowerCase();
  const cards = tariffCards.cards || [];

  useEffect(() => {
    if (!isLoading && !didRun.current && cards.length > 0) {
      if (tariffId) {
        const prefilledTariff = cards.find((card) => {
          return card.tariffs?.includes(tariffId.toUpperCase());
        });
        if (prefilledTariff && prefilledTariff.section) {
          setTab(prefilledTariff.section as TabType);
          reducer.giveTariff({
            partnerId,
            tariff: {
              id: prefilledTariff.tariffs?.find((tariff) => tariff.includes('_S')),
              section: prefilledTariff.section
            }
          });
        }
      }
      if (agentId) {
        reducer.updateValue({
          key: applicationField('agentId'),
          value: agentId
        });
      }
      didRun.current = true;
    }
  }, [isLoading]);
};
