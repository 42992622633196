import { useTranslation } from 'react-i18next';
import {
  AddressForm,
  Body,
  DisclaimerCheckbox,
  ForeignAddressForm,
  Form,
  GermanAddressForm,
  TabGroup,
  TabList,
  useApplicationHelpers,
  Heading as HeadingT,
  useExtendedReducer
} from 'lkh-portal-ui-library';
import Heading from 'components/Heading';
import { useHealthInsuranceContext } from 'contexts/HealthInsuranceContext';
import { WizardSlot } from 'layout/wizard/slots';
import { applicationField, partnerField } from 'models';
import { useConfigContext } from 'contexts/ConfigContext';
import { formatHealthDisclaimer } from './formatter';
import { HealthQuestionsForm } from 'pages/DigitalOrderPage/components/HealthQuestionsForm';
import { InsuranceHolderRelationForm } from 'pages/DigitalOrderPage/components/InsuranceHolderRelationForm';
import { ExtendedInsuredPersonForm } from 'pages/DigitalOrderPage/components/ExtendedInsuredPersonForm';
import { TariffSection } from '../TariffSection';
import classNames from 'classnames';
import styles from './index.module.scss';

import { NavigationSection } from 'pages/DigitalOrderPage/components/NavigationSection/NavigationSection';
import { Partner, PersonRoleEnum } from 'models/extension-generated';
import { useRef } from 'react';
import { LegalFooter } from 'components/LegalFooter';

const getPartnerKeys = (partnerId: string) => ({
  // Personal Data
  // InsuredPersonForm
  genderKey: partnerField(partnerId, 'gender'),
  salutationKey: partnerField(partnerId, 'salutation'),
  titleKey: partnerField(partnerId, 'title'),
  firstNameKey: partnerField(partnerId, 'firstname'),
  lastNameKey: partnerField(partnerId, 'lastname'),
  birthDateKey: partnerField(partnerId, 'birthDate'),
  fundsFromGZKey: partnerField(partnerId, 'applicationInformation.fundsFromGZ'),
  employmentTypeKey: partnerField(partnerId, 'applicationInformation.employmentGroup'),
  professionKey: partnerField(partnerId, 'applicationInformation.profession'),
  // InsuranceHolderRelation
  relationshipKey: partnerField(partnerId, 'applicationInformation.relationshipToHolder'),
  // GermanAddressForm
  livingInGermanyKey: partnerField(partnerId, 'livingInGermany'),
  // AddressForm
  streetKey: partnerField(partnerId, 'permanentResidence.street'),
  houseNumberKey: partnerField(partnerId, 'permanentResidence.houseNumber'),
  postalCodeKey: partnerField(partnerId, 'permanentResidence.postalCode'),
  cityKey: partnerField(partnerId, 'permanentResidence.city'),
  // ForeignAddressForm
  hasForeignResidenceKey: partnerField(partnerId, 'hasLivedInForeignCountry'),
  foreignCountryKey: partnerField(partnerId, 'foreignCountry'),
  // Health
  hasLegalObligationToNotify: partnerField(
    partnerId,
    'applicationInformation.hasLegalObligationToNotify'
  ),
  // Application
  applicationStartKey: applicationField('applicationStart')
});

export function InsuredPersonStep() {
  const { t } = useTranslation('insuredPersonStep');
  const mainRef = useRef<HTMLDivElement>(null);
  const { config: stateConfig } = useConfigContext();
  const { state, reducer, dispatch, partnerId, selectPartner } = useHealthInsuranceContext();
  const { getPartnersByRole, isHolderInsured, isHolderAlsoPayer, formatPartnerName } =
    useApplicationHelpers(state.model);
  const { setUniqueRole, clearRole, createPartner } = useExtendedReducer(dispatch);
  const partnerKeys = getPartnerKeys(partnerId);
  const insuredPartners = getPartnersByRole(PersonRoleEnum.INSURED_PERSON);

  const tabGroupOptions: Array<TabList> = insuredPartners.map((partner) => {
    const hasPartnerError = (partner: Partner): boolean => {
      return (
        Array.from(state.validationResults.keys()).filter((key) => key.includes(partner.id!))
          .length > 0
      );
    };
    const hasError = hasPartnerError(partner);

    const name = (() => {
      if (!partner.lastname && !partner.firstname) return t('Neue versicherte Person');
      let nameText = '';
      if (partner.lastname) nameText += partner.lastname;
      if (partner.lastname && partner.firstname) nameText += ', ';
      if (partner.firstname) nameText += partner.firstname;

      return nameText;
    })();
    return {
      id: partner.id || '',
      counter: 0,
      label: (
        <div
          className={classNames({
            ['border-b-primary border-b']: partner.id === partnerId
          })}
        >
          <Body
            bold={partner.id === partnerId}
            className={classNames({
              ['text-error-700']: hasError
            })}
            small
          >
            {name}
          </Body>
        </div>
      )
    };
  });

  return (
    <WizardSlot.Main ref={mainRef}>
      <div className="bg-white s:px-[80px] s:py-[32px] mb-m">
        <HeadingT className="text-secondary-600 text-center s:text-left m-[0px] py-m" bold>
          {t('title')}
        </HeadingT>
      </div>
      {insuredPartners.length > 1 && (
        <div className={styles.wrapperInner}>
          <TabGroup
            selected={partnerId}
            showDownloadIcon={false}
            list={tabGroupOptions}
            onTabChange={(id) => {
              selectPartner(String(id));
            }}
          />
          {/* <hr className="border-neutral m-0 w-full" /> */}
        </div>
      )}
      <Form state={state} dispatch={dispatch}>
        <TariffSection
          partnerKeys={{
            birthDateKey: partnerKeys.birthDateKey,
            applicationStartKey: partnerKeys.applicationStartKey
          }}
        />
        <div className={classNames('layout-res', styles.questionWrapperInner)}>
          <div className="grid-mx-res">
            <Heading level={1}>
              {t('insuredPeople.steps.personalData.title', { ns: 'wizardPrivateHealthInsurance' })}
            </Heading>
          </div>

          <ExtendedInsuredPersonForm
            partnerId={partnerId}
            partnerKeys={partnerKeys}
            reducer={reducer}
            config={stateConfig}
            state={state}
            getPartnersByRole={getPartnersByRole}
          />
          <div className="grid-res" />

          <div className="grid-mx-res">
            <Heading level={3}>{t('addressForm.section.title')}</Heading>
          </div>
          <AddressForm
            streetKey={partnerKeys.streetKey}
            houseNumberKey={partnerKeys.houseNumberKey}
            postalCodeKey={partnerKeys.postalCodeKey}
            cityKey={partnerKeys.cityKey}
            label={{
              title: undefined,
              street: {
                label: t('addressForm.fields.street.label')
              },
              houseNumber: {
                label: t('addressForm.fields.houseNumber.label')
              },
              postalCode: {
                label: t('addressForm.fields.postalCode.label')
              },
              city: {
                label: t('addressForm.fields.city.label')
              }
            }}
            disabled={false}
            AddressComponent={
              <GermanAddressForm
                livingInGermanyKey={partnerKeys.livingInGermanyKey}
                label={t('addressForm.fields.livingInGermany.label')}
                disabled={false}
              />
            }
          />
          <ForeignAddressForm
            hasForeignResidenceKey={partnerKeys.hasForeignResidenceKey}
            foreignCountryKey={partnerKeys.foreignCountryKey}
            state={state}
            label={{
              hasForeignResidence: t('foreignAddressForm.hasForeignResidence.label'),
              country: {
                label: t('foreignAddressForm.country.label')
              }
            }}
            disabled={false}
          />
          <div className="grid-res" />
          <InsuranceHolderRelationForm
            partnerId={partnerId}
            relationshipKey={partnerKeys.relationshipKey}
            reducer={reducer}
            title={t('wizardRelationship:section.title')}
            formatPartnerName={formatPartnerName}
            setUniqueRole={setUniqueRole}
            clearRole={clearRole}
            createPartner={createPartner}
            getPartnersByRole={getPartnersByRole}
            isHolderInsured={isHolderInsured}
            isHolderAlsoPayer={isHolderAlsoPayer}
          />
        </div>

        <div className="layout-res grid-my-res s:mx-[80px]">
          <div className="grid-mx-res">
            <Body bold className="text-secondary-600">
              {t('insuredPeople.steps.health.title', { ns: 'wizardPrivateHealthInsurance' })}
            </Body>
          </div>

          <div className="grid-res">
            <DisclaimerCheckbox
              componentKey={partnerKeys.hasLegalObligationToNotify}
              description={formatHealthDisclaimer()}
            />
          </div>

          <div className="grid-mx-res">
            <HealthQuestionsForm partnerId={partnerId} config={stateConfig} state={state} />
          </div>
        </div>
        <div className="grid-mx-res s:mx-[80px] text-[12px] font-[400] leading-[18px] tracking-normal text-lkh-text-info">
          {t('common:fieldRequired')}
        </div>
      </Form>
      <NavigationSection
        onPersonSwitch={() => {
          setTimeout(() => {
            mainRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
          }, 500);
        }}
      />
      <LegalFooter />
    </WizardSlot.Main>
  );
}
