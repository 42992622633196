import { Fragment, PropsWithChildren } from 'react';
import sortBy from 'lodash/sortBy';

import ReactMarkdown from 'react-markdown';
import {
  AddIcon,
  Body,
  Form,
  Icon,
  InformationCircleIcon,
  Label,
  QuestionsContextReturnType,
  TertiaryButton,
  Tooltip,
  formModelGet,
  getStringUuid,
  useExtendedReducer
} from 'lkh-portal-ui-library';
import { PartnerIdProp, RecursiveKeyOf } from 'lkh-portal-ui-library/dist/models';
import {
  HealthQuestionDetail,
  Partner,
  Question,
  QuestionTypeEnum
} from 'models/extension-generated';
import { useHealthInsuranceContext } from 'contexts/HealthInsuranceContext';
import classNames from 'classnames';
import { Markdown } from 'components/Markdown';
import { QuestionDetails } from './QuestionDetails';
import { partnerField } from 'models';
import { useTranslation } from 'react-i18next';

type QuestionsAction = Omit<
  QuestionsContextReturnType,
  'fetchQuestions' | 'questions' | 'isLoading'
>;

const { Input, YesNo } = Form.Components;

type QuestionWrapperProps = PropsWithChildren & {
  colClassName?: string;
  inputClassName?: string;
  placeholder?: string;
  hasUnit?: boolean;
  isDisabled?: boolean;
  onChange?: (value: any) => void;
};

function renderClosedQuestion(
  partnerId: string,
  key: string,
  action: QuestionsAction,
  tooltip?: string,
  hint?: string,
  props?: QuestionWrapperProps
) {
  return (
    <div className="grid-my-res">
      <div className="pb-[15px] flex">
        <Body small regular>
          {action.getQuestionText(key, false)}
        </Body>

        {tooltip && (
          <div
            style={{
              // TODO: move this color to the UI library
              color: '#00aca9'
            }}
          >
            <Tooltip
              tooltipContent={
                <div className="max-w-[50vw]">
                  <Body small>
                    <Markdown markdown={tooltip} />
                  </Body>
                </div>
              }
            >
              <Icon icon={<InformationCircleIcon />} className="ml-s mt-[2px]" />
            </Tooltip>
          </div>
        )}
      </div>
      {hint && (
        <div className="my-s bg-background gap-s flex items-center rounded-md">
          <Icon icon={<InformationCircleIcon />} className=" ml-s mt-[2px]" />
          <Label regular>
            <Markdown markdown={hint} />
          </Label>
        </div>
      )}
      <YesNo
        componentKey={action.getQuestionKey(partnerId, key)}
        onChange={(value) => {
          props?.onChange?.(value.value);
        }}
        disabled={props?.isDisabled}
      />
    </div>
  );
}

function renderInput(
  partnerId: string,
  key: string,
  action: QuestionsAction,
  tooltip?: string,
  props?: QuestionWrapperProps
) {
  return (
    <div className="grid-my-res">
      <div className="pb-[15px]">
        <Body small>{action.getQuestionText(key, false)}</Body>
        {tooltip && (
          <Tooltip
            tooltipContent={
              <div>
                <Body small>
                  <ReactMarkdown>{`${tooltip}`}</ReactMarkdown>
                </Body>
              </div>
            }
          >
            <Icon icon={<InformationCircleIcon />} className="ml-s" />
          </Tooltip>
        )}
      </div>
      <Input
        componentKey={action.getQuestionKey(partnerId, key)}
        label={action.getQuestionLabel(key)}
        disabled={props?.isDisabled}
        unitText={action.getQuestionUnit(key)}
        type="number"
        // innerText={getQuestionUnit(key)}
        // maxValue={getQuestionMaxValue(key)}
      />
    </div>
  );
}

type QuestionsProps = PartnerIdProp & { action: QuestionsAction } & {
  questions: Question[];
  /**
   * Whether there is loading event in progress.
   * This variable is true in following cases:
   *  - application load
   *  - initial configuration load
   */
  isLoading: boolean;
};

export const getSubQuestionsField = (partnerId: string, questionId: string): string => {
  return partnerField(
    partnerId,
    `applicationInformation.health[${questionId}].details` as RecursiveKeyOf<Partner>
  );
};

/* common questions formatter */
const Questions = ({ partnerId, questions, action }: QuestionsProps) => {
  const { t } = useTranslation('wizardHealth');
  const { state, dispatch } = useHealthInsuranceContext();
  const { addDetail, removeQuestionDetails, removeDetail } = useExtendedReducer(dispatch);

  return (
    <>
      {questions.map((question) => {
        const { id: questionId } = question;
        const mainQuestionKey = action.getQuestionKey(partnerId, question.id);
        const mainQuestionValue = formModelGet(state.model, mainQuestionKey);
        const detailsKey = getSubQuestionsField(partnerId, questionId);
        const details: HealthQuestionDetail[] = sortBy(
          formModelGet(state.model, detailsKey) || [],
          'orderNo'
        );

        const handleAddDiagnosisClick = () => {
          const newId = getStringUuid();
          addDetail({
            detailId: newId,
            partnerId,
            questionId
          });
        };
        const sortedSubQuestions = sortBy(question.subQuestions || [], 'orderNo');
        return (
          <Fragment key={question.id}>
            {question.headline && (
              <div className="mb-m s:mb-s">
                <Body bold className="text-secondary-600">
                  {question.headline}
                </Body>
              </div>
            )}

            {question.questionType === QuestionTypeEnum.CLOSED_QUESTION &&
              renderClosedQuestion(
                partnerId,
                question.id,
                action,
                question.tooltip,
                question.hint,
                {
                  onChange: (value) => {
                    if (value === true && sortedSubQuestions.length > 0) {
                      addDetail({
                        detailId: getStringUuid(),
                        partnerId,
                        questionId
                      });
                    } else {
                      removeQuestionDetails({
                        partnerId,
                        questionId
                      });
                    }
                  }
                }
              )}
            {question.questionType === QuestionTypeEnum.INT_QUESTION &&
              renderInput(partnerId, question.id, action, question.hint)}

            {question.effectiveHint && mainQuestionValue === true && (
              <div className="my-s gap-s flex text-error-700 items-center rounded-sm">
                <Icon icon={<InformationCircleIcon />} className=" ml-s mt-[2px]" />

                <Label regular>
                  <Markdown markdown={question.effectiveHint} />
                </Label>
              </div>
            )}
            <div
              className={classNames('space-y-6', {
                'mt-l': mainQuestionValue && sortedSubQuestions.length > 0
              })}
            >
              <>
                {/* Render existing question details */}
                {details.map((detail) => {
                  return (
                    <>
                      <div>
                        <Body bold className="text-secondary-600">
                          {t('section.details.title')}
                        </Body>
                        {details.length > 1 && (
                          <TertiaryButton
                            className="underline"
                            onClick={() => {
                              removeDetail({
                                partnerId,
                                questionId,
                                detailId: detail.id || ''
                              });
                            }}
                          >
                            {t('section.details.deleteAction')}
                          </TertiaryButton>
                        )}
                      </div>
                      {/* Map all subquestions for this detail */}
                      {sortedSubQuestions.map((subQuestion) => {
                        return (
                          <QuestionDetails
                            key={subQuestion.id}
                            detailId={detail.id || ''}
                            partnerId={partnerId}
                            questionId={questionId}
                            subQuestion={subQuestion}
                            question={question}
                            mainQuestionValue={mainQuestionValue}
                          />
                        );
                      })}
                      <hr className="h-px border-0 bg-neutral-100" />
                    </>
                  );
                })}

                {mainQuestionValue === true && sortedSubQuestions.length > 0 && (
                  <div className="pb-m">
                    <TertiaryButton onClick={handleAddDiagnosisClick}>
                      <Icon icon={<AddIcon />} className="mr-s" />
                      {t('section.details.addDetailAction')}
                    </TertiaryButton>
                  </div>
                )}
              </>
            </div>
          </Fragment>
        );
      })}
    </>
  );
};

export default Questions;
