import { useState } from 'react';
import Fab from '@mui/material/Fab';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { ContactSupportDialog } from 'components/ContactSupportDialog';

export function ContactSupport() {
  const [isDialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <div className="fixed left-[20px] bottom-[48px] z-[100] invisible m:visible">
        <Fab
          aria-label="info"
          sx={{
            width: 64,
            height: 64,
            backgroundColor: '#F0E648',
            ':hover': { backgroundColor: '#E7DA13' }
          }}
          onClick={() => setDialogOpen(true)}
        >
          <SupportAgentIcon sx={{ width: 32, height: 32 }} />
        </Fab>
      </div>
      <div className="fixed left-[16px] bottom-[72px] z-[100] m:invisible s:visible">
        <Fab
          aria-label="info"
          sx={{
            width: 48,
            height: 48,
            backgroundColor: '#F0E648',
            ':hover': { backgroundColor: '#E7DA13' }
          }}
          onClick={() => setDialogOpen(true)}
        >
          <SupportAgentIcon sx={{ width: 24, height: 24 }} />
        </Fab>
      </div>
      {isDialogOpen && (
        <ContactSupportDialog isOpen={isDialogOpen} onClose={() => setDialogOpen(false)} />
      )}
    </>
  );
}
