import {
  Dialog,
  DialogSize,
  Form,
  PrimaryButton,
  TertiaryButton,
  useApplicationHelpers,
  AlertIcon,
  Icon,
  Body
} from 'lkh-portal-ui-library';
import { Trans, useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import { useHealthInsuranceContext } from 'contexts/HealthInsuranceContext';
import cloneDeep from 'lodash/cloneDeep';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { partnerField } from 'models';
import { ApiError, ApplicationService, PersonRoleEnum } from 'models/extension-generated';
import PersonIcon from '@mui/icons-material/Person';

import styles from './ContactSupportDialog.module.scss';
// import { useState } from 'react';
// import Tooltip from '@mui/material/Tooltip';
import { downloadDisclaimerDocument } from 'utils/fetchDocument';
import classNames from 'classnames';

// const { Input, Checkbox } = Form.Components;

// const getPartnerKeys = (id: string) => ({
//   phoneKey: partnerField(id, 'permanentResidence.phone'),
//   emailKey: partnerField(id, 'permanentResidence.email'),
//   acceptsDataProtection: partnerField(id, 'applicationInformation.acceptsDataProtection')
// });

type ContactSupportDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ContactSupportDialog = ({ isOpen, onClose }: ContactSupportDialogProps) => {
  // const [errorMessage, setErrorMessage] = useState('');

  const { t } = useTranslation('contactSupport');
  const handleCloseDialog = () => onClose();
  const { partnerId, state, dispatch } = useHealthInsuranceContext();
  // const { getPartnersByRole } = useApplicationHelpers(state.model);
  // const partnerKeys = getPartnerKeys(partnerId);

  // TODO to be enabled
  // const handleConfirmDialog = async (): Promise<void> => {
  //   try {
  //     // TODO UI temporary validation should be improved
  //     // UI validation
  //     const insuredPartner = getPartnersByRole(PersonRoleEnum.INSURED_PERSON)[0];

  //     const email = insuredPartner.permanentResidence.email?.trim();
  //     const phone = insuredPartner.permanentResidence.phone?.trim();

  //     if (!email?.length) {
  //       setErrorMessage(t('validation.number'));
  //       return;
  //     }
  //     if (!phone?.length) {
  //       setErrorMessage(t('validation.email'));
  //       return;
  //     }
  //     if (!insuredPartner.applicationInformation?.acceptsDataProtection) {
  //       setErrorMessage(t('validation.disclaimer'));
  //       return;
  //     }

  //     // BE validation
  //     const cloned = { ...cloneDeep(state.model) };
  //     await ApplicationService.createPublicApplication({ requestBody: cloned });
  //     setErrorMessage('');
  //     handleCloseDialog();
  //   } catch (error) {
  //     if (error instanceof ApiError) {
  //       setErrorMessage(error.body.detail);
  //     }
  //   }
  // };
  // const downloadDisclaimer = async () => {
  //   try {
  //     await downloadDisclaimerDocument(state.model.applicationStart);
  //   } catch (error) {
  //     throw new Error(`Error occurred during download: ${error}`);
  //   }
  // };

  return (
    <Dialog
      dialogMainClassName="flex flex-col w-[408px] s:w-[708px]"
      size={DialogSize.Custom}
      className="absolute"
      open={isOpen}
      onClose={handleCloseDialog}
      closeIcon={<CloseIcon className="text-lkh-text-info" />}
      header={
        <div className="text-[18px] s:text-[20px] font-[700] leading-[28px] text-secondary-600">
          {t('header')}
        </div>
      }
    >
      <Form state={state} dispatch={dispatch}>
        <div className="flex gap-16 mt-[16px]">
          <div className="flex flex-col gap-12 mb-[40px] w-full">
            <div className="text-[14px] font-[400] leading-[20px] text-lkh-text-info mb-[24px]">
              {t('description1')}
            </div>
            <div className="flex items-center justify-center mb-[40px]">
              <div className="rounded-[4px] bg-lkh-color-surface-60 p-[10px] s:p-[24px] w-full">
                <div className="grid grid-cols-1 s:grid-cols-2">
                  <div className="flex">
                    <div className="flex space-x-2">
                      <div>
                        <CallIcon />
                      </div>
                      <div>
                        <div className="grid-cols-subgrid">
                          <div className="col-start-1 text-[12px] font-[700] leading-[16px] tracking-normal text-left inline-block align-middle">
                            {t('contact.number.title')}
                          </div>
                          <div className="col-start-2 text-lkh-text-info text-[12px] font-[400] leading-[16px] tracking-normal text-left pt-s">
                            {t('contact.number.detail')}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex space-x-2 mt-l s:mt-[0px]">
                    <div>
                      <EmailIcon />
                    </div>
                    <div>
                      <div className="grid-cols-subgrid">
                        <div className="col-start-1 text-[12px] font-[700] leading-[18px] tracking-normal text-left inline-block align-middle">
                          <a
                            className="text-lkh-text-80"
                            href={`mailto:${t('contact.email.title')}`}
                          >
                            {t('contact.email.title')}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex space-x-2 mt-l">
                    <div>
                      <LocationOnIcon />
                    </div>
                    <div>
                      <div className="grid-cols-subgrid">
                        <div className="col-start-1 text-[12px] font-[700] leading-[18px] tracking-normal text-left inline-block align-middle">
                          {t('contact.location.title')}
                        </div>
                        <div className="col-start-2 text-lkh-text-info text-[12px] font-[400] leading-[16px] tracking-normal text-left pt-s">
                          {t('contact.location.detail1')}
                          <br />
                          {t('contact.location.detail2')}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex space-x-2 mt-l ">
                    <div>
                      <PersonIcon />
                    </div>
                    <div>
                      <div className="grid-cols-subgrid">
                        <div className="col-start-1 text-[12px] font-[700] leading-[18px] tracking-normal text-left inline-block align-middle">
                          {t('contact.person.title')}
                        </div>
                        <div className="col-start-2 text-lkh-text-info text-[12px] font-[400] leading-[16px] tracking-normal text-left pt-s">
                          <a
                            href="https://www.lkh.de/service/serviceportal/beratersuche"
                            target="_blank"
                          >
                            <div className="flex items-center space-x-2">
                              <div>{t('contact.person.detail1')}</div>
                              <div>
                                <OpenInNewIcon className="text-primary" />
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* TODO to be enabled */}
            {/* <div className="text-[14px] font-[400] leading-[20px] text-lkh-text-info">
              {t('description2')}
            </div> */}
            {/* <div className="grid grid-cols-2 grid-gap-res mb-[32px]">
              <div>
                <Input componentKey={partnerKeys.emailKey} label={t('fields.email')} />
              </div>
              <div>
                <Input componentKey={partnerKeys.phoneKey} label={t('fields.phone')} />
              </div>
            </div>

            <div className="bg-lightGray p-[24px] mb-[40px] rounded-2">
              <span>
                <Checkbox
                  componentKey={partnerKeys.acceptsDataProtection}
                  description={
                    <Body small>
                      <Trans
                        i18nKey={'summaryPage:dataProtectionDescription'}
                        components={[
                          <a
                            onClick={downloadDisclaimer}
                            className="m-px text-logo-500 cursor-pointer inline underline"
                          />
                        ]}
                      />
                    </Body>
                  }
                />
              </span>
            </div> */}
          </div>
        </div>
        <div className="w-full flex justify-end items-center gap-16">
          <TertiaryButton
            className="text-[14px] font-[700] leading-[20px]"
            children={t('cancelButton')}
            onClick={handleCloseDialog}
          />
          {/* TODO to be enabled */}
          {/* {errorMessage && (
            <Tooltip title={errorMessage} arrow placement="top" open={isOpen}>
              <div className={styles.validationError}>
                <Icon icon={<AlertIcon />} />
              </div>
            </Tooltip>
          )}
          <PrimaryButton
            className="text-[14px] font-[700] leading-[20px]"
            children={t('acceptButton')}
            disabled={false}
            onClick={handleConfirmDialog}
          /> */}
        </div>
      </Form>
    </Dialog>
  );
};
