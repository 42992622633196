import { ConfigResponse, ConfigService } from 'models/extension-generated';
import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type ConfigContextType = {
  config: ConfigResponse;
  isLoading: boolean;
};

export const ConfigContext = createContext<ConfigContextType>(null!);

export const ConfigContextProvider = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation('common');

  const [config, setConfig] = useState<ConfigResponse>({});
  const [isConfigLoading, setIsConfigLoading] = useState(false);

  const fetchConfigFn = async () => {
    setIsConfigLoading(true);
    try {
      const response = await ConfigService.getPublicConfig();
      setConfig(response);
      setIsConfigLoading(false);
    } catch (error) {
      const genericError = t('common:toastMessages.somethingWentWrong');
      toast.error(genericError);
    }
  };

  useEffect(() => {
    fetchConfigFn();
  }, []);

  return (
    <ConfigContext.Provider
      value={{
        config,
        isLoading: isConfigLoading
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export function useConfigContext(): ConfigContextType {
  const configContext = useContext(ConfigContext);

  if (!configContext) {
    throw new Error(
      'To use "useConfigContext" some of the parent components must be in <ConfigContextProvider>'
    );
  }

  return configContext;
}
