import classNames from 'classnames';
import { Caption, Tooltip } from 'lkh-portal-ui-library';
import { TariffDescription } from 'components/TariffDescription';
import styles from './TariffLabel.module.scss';

export const TariffLabel = ({ label, tooltip }: { label: string; tooltip: string }) => {
  return (
    <Tooltip
      tooltipContent={tooltip && <TariffDescription description={tooltip} />}
      showArrow
      tooltipDebounce={300}
      tooltipOffset={7}
      placement="right"
    >
      <Caption bold className={classNames(styles.tariffId, 'p-v-4', 'p-h-12')}>
        {label}
      </Caption>
    </Tooltip>
  );
};
