import React from 'react';
import Heading from 'components/Heading';
import Questions from 'components/Questions/Questions';
import {
  FormState,
  Icon,
  InformationCircleIcon,
  Label,
  Loader,
  LoaderType,
  useQuestionsContext
} from 'lkh-portal-ui-library';
import groupBy from 'lodash/groupBy';
import { Application, ConfigResponse } from 'models/extension-generated';
import { useTranslation } from 'react-i18next';
import { Question } from 'lkh-portal-ui-library/dist/models/extension-generated';
import { PartnerIdProp } from 'lkh-portal-ui-library/dist/models';
import { NoHealthQuestions } from './NoHealthQuestions/NoHealthQuestions';
import { useHealthInsuranceContext } from 'contexts/HealthInsuranceContext';
import { partnerField } from 'models';

export type HealthQuestionsFormProps = PartnerIdProp & {
  config: ConfigResponse;
  state: FormState<Application>;
  disabled?: boolean;
};

export function HealthQuestionsForm({ partnerId }: HealthQuestionsFormProps) {
  const { t } = useTranslation();

  const {
    isLoading,
    questions,
    getQuestionLabel,
    getQuestionText,
    getQuestionUnit,
    getQuestionMaxValue,
    getQuestionKey
  } = useQuestionsContext();

  const groupedQuestions = groupBy(questions, 'sections');

  return (
    <>
      {isLoading && (
        <Loader className="m-xl flex items-center justify-center" type={LoaderType.Circular} />
      )}
      {!isLoading && questions.length === 0 && <NoHealthQuestions />}
      {!isLoading && (
        <>
          {Object.values(groupedQuestions).map((questions: Question[]) => {
            return (
              <div key={questions[0].sections?.join(',')}>
                <div className="overflow-hidden text-ellipsis">
                  <Heading level={1}>
                    {t(`wizard.healthSection.groups.${questions[0].sections}`)}
                  </Heading>
                </div>
                <Questions
                  partnerId={partnerId}
                  questions={questions as unknown as Question[]}
                  isLoading={isLoading}
                  action={{
                    getQuestionLabel,
                    getQuestionText,
                    getQuestionUnit,
                    getQuestionMaxValue,
                    getQuestionKey
                  }}
                />
              </div>
            );
          })}
        </>
      )}
      {questions.length !== 0 && (
        <div className="my-s p-[8px] bg-background gap-s flex items-center rounded-md">
          <Icon icon={<InformationCircleIcon />} />

          <span>
            <Label regular className="inline">
              <b>{t('wizard.healthSection.info.boldText')}</b>
              {t('wizard.healthSection.info.text')}
            </Label>
          </span>
        </div>
      )}
    </>
  );
}
