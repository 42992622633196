import Heading from 'components/Heading';
import {
  Form,
  GenderForm,
  BirthDateForm,
  DropdownOption,
  FormState,
  formModelSet,
  formModelGet
} from 'lkh-portal-ui-library';
import { PartnerFormReducers, PartnerIdProp } from 'lkh-portal-ui-library/dist/models';
import { Application, ConfigResponse, Partner, PersonRoleEnum } from 'models/extension-generated';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { genderOptions, titleOptions } from 'utils/dropdowns';
import { getInsuranceStartDates } from 'utils/getInsuranceStartDates';

const { Input, Dropdown } = Form.Components;

export type ExtendedInsuredPersonFormProps = PartnerIdProp & {
  partnerKeys: {
    genderKey: string;
    salutationKey: string;
    titleKey: string;
    firstNameKey: string;
    lastNameKey: string;
    birthDateKey: string;
    fundsFromGZKey: string;
    applicationStartKey: string;
    employmentTypeKey: string;
    professionKey: string;
  };
  reducer: Pick<PartnerFormReducers, 'updateValue'>;
  config: ConfigResponse;
  state: FormState<Application>;
  disabled?: boolean;
  getPartnersByRole: (role: PersonRoleEnum) => Array<Partner>;
};

export const ExtendedInsuredPersonForm = ({
  partnerId,
  partnerKeys,
  reducer: { updateValue },
  config,
  state,
  disabled = false,
  getPartnersByRole
}: ExtendedInsuredPersonFormProps) => {
  const { t } = useTranslation('ExtendedInsuredPersonForm');

  const {
    genderKey,
    salutationKey,
    titleKey,
    firstNameKey,
    lastNameKey,
    birthDateKey,
    fundsFromGZKey,
    applicationStartKey,
    employmentTypeKey,
    professionKey
  } = partnerKeys;

  // applicationStart component
  const partners: Array<Partner> = getPartnersByRole(PersonRoleEnum.INSURED_PERSON);
  const isCurrentPartnerFirst = partners[0]?.id === partnerId;
  const availableStartDates: Array<DropdownOption> = useMemo(getInsuranceStartDates, []);

  // employmentType and profession components
  const employmentType = formModelGet(state.model, employmentTypeKey);
  // Group and profession dropdown values
  const employmentGroupOptions: Array<DropdownOption> = (() => {
    const groupItems = config.employmentGroupItems || [];

    return groupItems.map((group) => {
      return {
        value: group.id || '',
        label: t(`EmploymentGroupEnum.${group.id}`, { ns: 'contractsEnums' })
      };
    });
  })();
  const professionOptions: Array<DropdownOption> = (() => {
    const selectedGroup = config.employmentGroupItems?.find((group) => group.id === employmentType);

    if (selectedGroup?.items) {
      return selectedGroup.items.map((item) => {
        return {
          value: item,
          label: t(`ProfessionEnum.${item}`, { ns: 'contractsEnums' }),
          tooltip: t(`ProfessionEnum.${item}`, { ns: 'contractsEnums' })
        };
      });
    }
    return [];
  })();

  const handleEmploymentGroupChange = () => {
    formModelSet(state.model, professionKey, undefined);
  };

  return (
    <>
      <div className="grid-mx-res">
        <Heading level={3}>{t('section.title')}</Heading>
      </div>
      <Form.Section>
        <div className="grid-res-2">
          <div>
            <BirthDateForm
              partnerId={partnerId}
              label={t('birthDate.label')}
              birthDateKey={birthDateKey}
              fundsFromGZKey={fundsFromGZKey}
              disabled={disabled}
              updateValue={updateValue}
            />
          </div>
          <div>
            <Dropdown
              componentKey={applicationStartKey}
              options={availableStartDates}
              label={t('insuranceStart.label')}
              placeholder={t('insuranceStart.placeholder')}
              disabled={!isCurrentPartnerFirst}
            />
          </div>
        </div>
        <div className="grid-res-2">
          <div>
            <GenderForm
              genderKey={genderKey}
              salutationKey={salutationKey}
              options={genderOptions}
              disabled={disabled}
              updateValue={updateValue}
            />
          </div>
        </div>

        <div className="grid-res-2">
          <div>
            <Input
              componentKey={firstNameKey}
              label={t('personalData.fields.firstName.placeholder')}
              disabled={disabled}
            />
          </div>
          <div>
            <Input
              componentKey={lastNameKey}
              label={t('personalData.fields.lastName.placeholder')}
              disabled={disabled}
            />
          </div>
        </div>

        <div className="grid-res-2">
          <div>
            <Dropdown
              componentKey={titleKey}
              label={t('personalData.fields.title.label')}
              placeholder={t('personalData.fields.title.placeholder')}
              disabled={disabled}
              options={titleOptions}
              unsetOption={{
                label: t('contractsEnums:None'),
                value: '',
                type: 'option'
              }}
            />
          </div>
        </div>

        <div className="grid-res-2">
          <div>
            <Dropdown
              componentKey={employmentTypeKey}
              onChange={handleEmploymentGroupChange}
              options={employmentGroupOptions}
              label={t('employmentData.fields.employmentType.label')}
              placeholder={t('employmentData.fields.employmentType.placeholder')}
              disabled={disabled}
            />
          </div>
          <div>
            <Dropdown
              componentKey={professionKey}
              options={professionOptions}
              label={t('employmentData.fields.profession.label')}
              placeholder={t('employmentData.fields.profession.placeholder')}
              disabled={disabled || professionOptions.length == 0}
              multiline
              sizeToFit
            />
          </div>
        </div>
      </Form.Section>
    </>
  );
};
