export const IconLeft = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_3373_63361" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3373_63361)">
        <path
          d="M14.0004 6L15.4004 7.4L10.8004 12L15.4004 16.6L14.0004 18L8.00039 12L14.0004 6Z"
          fill="#6E6E6E"
        />
      </g>
    </svg>
  );
};
