import { TFunction, useTranslation } from 'react-i18next';

import {
  AddressForm,
  DisclaimerCheckbox,
  Form,
  PayerRelation,
  useCustomValidation
} from 'lkh-portal-ui-library';

import { InsuredPersonForm } from 'pages/DigitalOrderPage/components/InsuredPersonForm';
import Heading from 'components/Heading';
import { PartnerFormReducers } from 'lkh-portal-ui-library/dist/models';
import {
  ClearRolePayload,
  CreatePartnerPayload,
  SetUniqueRolePayload
} from 'lkh-portal-ui-library/dist/hooks/business/useExtendedReducer/reducers';
import { EVENT_IDS } from 'pages/DigitalOrderPage/constants';

const { Input } = Form.Components;

const validateIban = async (
  newValue: string,
  bicNumberKey: string,
  bankNameKey: string,
  ibanKey: string,
  reducer: Pick<PartnerFormReducers, 'updateMultipleValues' | 'setDirty' | 'setPristine'>,
  getIbanInfo: (payload: any) => Promise<{ bankName?: string; bic?: string }>,
  t: TFunction<'BankDetailsForm', undefined>
) => {
  const { updateMultipleValues, setDirty, setPristine } = reducer;

  setPristine([bicNumberKey, bankNameKey]);
  updateMultipleValues([
    {
      key: bicNumberKey,
      value: ''
    },
    {
      key: bankNameKey,
      value: ''
    }
  ]);

  if (!newValue) return null;

  try {
    const { bankName, bic } = await getIbanInfo({
      iban: newValue
    });

    updateMultipleValues([
      {
        key: bicNumberKey,
        value: bic
      },
      {
        key: bankNameKey,
        value: bankName
      }
    ]);

    return null;
  } catch (error) {
    setDirty(ibanKey);
    return t('bank.fields.iban.error');
  }
};

type BankDetailsFormProps = {
  holderId: string;
  payerId: string;
  payerKeys: {
    genderKey: string;
    birthDateKey: string;
    salutationKey: string;
    titleKey: string;
    firstNameKey: string;
    lastNameKey: string;
    fundsFromGZKey: string;
    maritalStatusKey: string;
    streetKey: string;
    houseNumberKey: string;
    postalCodeKey: string;
    cityKey: string;
    countryKey: string;
    ibanKey: string;
    bankNameKey: string;
    bicNumberKey: string;
    hasPaymentAuthorizationKey: string;
  };
  reducer: Pick<
    PartnerFormReducers,
    'updateValue' | 'updateMultipleValues' | 'setDirty' | 'setPristine'
  >;
  setUniqueRole: (payload: SetUniqueRolePayload) => void;
  clearRole: (payload: ClearRolePayload) => void;
  createPartner: (payload: CreatePartnerPayload) => void;
  /**
   * Method should be using API service for IBAN validation and information retrieval for BankDetailsForm
   * @param {iban} - at least iban number is required in payload object.
   *
   *  e.g.
   * @returns {bankName, bic} - bankName: name of the bank for IBAN information, bic: bank identifier code for precise bank identification
   */
  getIbanInfo: (payload: any) => Promise<{ bankName?: string; bic?: string }>;
};

export const BankDetailsForm = ({
  holderId,
  payerId,
  payerKeys,
  reducer,
  setUniqueRole,
  clearRole,
  createPartner,
  getIbanInfo
}: BankDetailsFormProps) => {
  const { t } = useTranslation('BankDetailsForm');
  const {
    streetKey,
    houseNumberKey,
    postalCodeKey,
    cityKey,
    countryKey,
    ibanKey,
    bankNameKey,
    bicNumberKey,
    hasPaymentAuthorizationKey
  } = payerKeys;

  useCustomValidation(ibanKey, (newValue) =>
    validateIban(newValue, bicNumberKey, bankNameKey, ibanKey, reducer, getIbanInfo, t)
  );
  const isSame = holderId === payerId;

  return (
    <>
      <PayerRelation
        holderId={holderId}
        payerId={payerId}
        setUniqueRole={setUniqueRole}
        clearRole={clearRole}
        createPartner={createPartner}
      />
      <InsuredPersonForm
        partnerId={payerId}
        partnerKeys={payerKeys}
        reducer={reducer}
        hasTitle
        text={{
          title: t('personalInfo.section.title'),
          firstName: {
            label: t('personalInfo.firstName.label')
          },
          lastName: {
            label: t('personalInfo.lastName.label')
          }
        }}
        disabled={isSame}
      />

      <div className="grid-mx-res">
        <Heading level={3}>{t('address.section.title')}</Heading>
      </div>
      <AddressForm
        streetKey={streetKey}
        houseNumberKey={houseNumberKey}
        postalCodeKey={postalCodeKey}
        cityKey={cityKey}
        label={{
          title: undefined,
          street: {
            label: t('address.fields.street.label')
          },
          houseNumber: {
            label: t('address.fields.houseNumber.label')
          },
          postalCode: {
            label: t('address.fields.postalCode.label')
          },
          city: {
            label: t('address.fields.city.label')
          }
        }}
        disabled={isSame}
      />
      <div className="grid-res-2">
        <Input componentKey={countryKey} label={t('address.fields.country.label')} />
      </div>

      <div className="grid-mx-res">
        <Heading level={3}>{t('bank.section.title')}</Heading>
      </div>
      <div className="grid-res-2">
        <div>
          <Input componentKey={ibanKey} label={t('bank.fields.iban.label')} />
        </div>
        <div>
          <Input componentKey={bankNameKey} label={t('bank.fields.bankName.label')} disabled />
        </div>
      </div>

      <div className="grid-res-2">
        <div>
          <Input componentKey={bicNumberKey} label={t('bank.fields.bicNumber.label')} disabled />
        </div>
      </div>

      <div className="grid-res">
        <div>
          <DisclaimerCheckbox
            id={EVENT_IDS.SEPA_CHECKBOX}
            componentKey={hasPaymentAuthorizationKey}
            description={
              <div>
                {t('bank.fields.hasPaymentAuthorization.paragraph1')} <br />
                <b>{t('bank.fields.hasPaymentAuthorization.notice')}: </b>
                {t('bank.fields.hasPaymentAuthorization.paragraph2')}
              </div>
            }
          />
        </div>
      </div>
    </>
  );
};
