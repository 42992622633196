import { useHealthInsuranceContext } from 'contexts/HealthInsuranceContext';
import { DropdownOption, MaterialSelect, formModelGet } from 'lkh-portal-ui-library';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { getMonths, getYears } from './utils';

export type MonthYear = {
  month?: number;
  year?: number;
};

export const CustomMonthYearPicker = ({
  onChange,
  componentKey
}: {
  onChange: (value: string) => void;
  componentKey: string;
}) => {
  const { state, reducer } = useHealthInsuranceContext();
  const value = formModelGet(state.model, componentKey);
  const currentYear = new Date().getFullYear();
  const [date, setDate] = useState<MonthYear>({ month: undefined, year: undefined });

  const selectedMonth: DropdownOption[] | undefined = date.month
    ? [{ value: date.month, label: `${date.month}` }]
    : undefined;
  const selectedYear: DropdownOption[] | undefined = date.year
    ? [{ value: date.year, label: `${date.year}` }]
    : undefined;

  // Side Effect - update date when value changes
  useEffect(() => {
    if (!value) {
      setDate({ month: undefined, year: undefined });
      return;
    }
    const date = moment(value, 'YYYY-MM-DD');
    const month = date.month() + 1;
    const year = date.year();

    setDate({ month, year });
  }, [value]);

  const years = getYears(currentYear);
  const months = getMonths();

  const handleOnChange = (key: keyof MonthYear, value: DropdownOption | null) => {
    const val = Number(value?.value || 0);
    const { month, year }: MonthYear = { ...date, [key]: val };

    setDate((prev) => ({
      ...prev,
      [key]: val
    }));

    // change callback in format YYYY-MM-DD

    if (month && year) {
      const formattedDate = (() => {
        const formattedMonth = month < 10 ? `0${month}` : month;
        return `${year}-${formattedMonth}-01`;
      })();
      onChange?.(formattedDate);
      reducer.updateValue({
        key: componentKey,
        value: formattedDate
      });
    }
  };

  return (
    <div className="flex gap-s flex-row s:justify-start w-full">
      <MaterialSelect
        // TODO: add translations
        label="Monat"
        value={selectedMonth}
        options={months}
        onSelect={(value) => handleOnChange('month', value)}
      />
      <MaterialSelect
        // TODO: add translations
        label="Jahr"
        value={selectedYear}
        options={years}
        onSelect={(value) => handleOnChange('year', value)}
      />
    </div>
  );
};
