/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type HealthQuestionDetail = {
    id?: string;
    orderNo: number;
    /**
     * Diagnosis
     */
    diagnosis: string;
    /**
     * Behandlungsart
     */
    treatmentType?: HealthQuestionDetail.treatmentType;
    /**
     * Behandlung abgeschlossen
     */
    treatmentDone?: boolean;
    /**
     * Start of the treatment (Von wann bis wann aufgetreten/behandelt usw.?)
     */
    treatmentStart?: string;
    /**
     * End of the treatment (Von wann bis wann aufgetreten/behandelt usw.?)
     */
    treatmentEnd?: string;
    hasConsenquences?: boolean;
    symptomsFreeFrom?: string;
    /**
     * If patient was operated
     */
    hasOperation?: boolean;
    operationDate?: string;
    /**
     * For how long was the patient on sick leave (Dauer der Arbeits-unfähigkeit?)
     */
    sickLeaveDuration?: string;
    /**
     * Name & contact to the doctor (Name und Anschriften der Ärzte/Behandler, Krankenhäuser, Heilstätten usw.?)
     */
    doctor?: string;
};

export namespace HealthQuestionDetail {

    /**
     * Behandlungsart
     */
    export enum treatmentType {
        AMBULANT = 'AMBULANT',
        STATIONER = 'STATIONER',
        NOTHING = 'NOTHING',
    }


}

