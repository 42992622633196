import { CellValueChangedEvent, GridApi, GridReadyEvent, RowClassParams } from 'ag-grid-community';
import { Table } from 'lkh-portal-ui-library';
import { ReactNode, useEffect, useState } from 'react';
import { getColumnDefinitions } from './columnDefinitions';

import styles from './OfferTariffTable.module.scss';

export type TariffRow = {
  id: string;
  product: ReactNode;
  label?: ReactNode;
  price?: ReactNode;
  actionComponent?: ReactNode;
  highMoneyAmount?: string | ReactNode;
  entitlement?: ReactNode;
  addedRiskAmount?: number;
};

type TariffProps = {
  allowEditing?: boolean;
  tariffs: Array<TariffRow>;
  onTariffEdit?: (event: CellValueChangedEvent<TariffRow>) => void;
  summaryRow: TariffRow;
};

export const OfferTariffTable = ({
  allowEditing = true,
  tariffs,
  summaryRow,
  onTariffEdit
}: TariffProps) => {
  const [gridApi, setGridApi] = useState<GridApi>();

  useEffect(() => {
    gridApi?.setRowData(tariffs);
  }, [tariffs.length]);

  const handleGridReady = (event: GridReadyEvent) => {
    event.api.sizeColumnsToFit();
    setGridApi(event.api);
  };

  const handleCellEdit = (event: CellValueChangedEvent<TariffRow>) => {
    allowEditing && onTariffEdit?.(event);
  };

  return (
    <div className="table-override">
      <Table
        columnDefs={getColumnDefinitions(allowEditing)}
        onCellValueChanged={handleCellEdit}
        rowData={[...tariffs, summaryRow]}
        onGridReady={handleGridReady}
        enableCellTextSelection={false}
        suppressCellFocus
        suppressDragLeaveHidesColumns
        tooltipShowDelay={0}
        tooltipMouseTrack
        stopEditingWhenCellsLoseFocus
        getRowClass={(params: RowClassParams) => {
          const isLastRow =
            params.node && params.node.rowIndex === params.api.getDisplayedRowCount() - 1;
          return isLastRow ? styles.lastRow : styles.row;
        }}
        defaultColDef={{
          suppressSizeToFit: false,
          autoHeight: true,
          suppressMovable: true
        }}
        domLayout={'autoHeight'}
      />
    </div>
  );
};
