import { DropdownOption, SelectOption } from 'lkh-portal-ui-library';
import {
  GenderEnum,
  MaritalStatusEnum,
  RelationshipToHolderEnum,
  TitleEnum
} from 'models/extension-generated';
import i18n from 'services/translation';

export const genderOptions: Array<SelectOption> = [
  {
    value: GenderEnum.MALE,
    label: i18n.t('contractsEnums:GenderEnum.MALE'),
    icon: <></>
  },
  {
    value: GenderEnum.FEMALE,
    label: i18n.t('contractsEnums:GenderEnum.FEMALE'),
    icon: <></>
  },
  {
    value: GenderEnum.UNKNOWN,
    label: i18n.t('contractsEnums:GenderEnum.UNKNOWN'),
    icon: <></>
  }
];

export const titleOptions: Array<SelectOption> = [
  {
    label: i18n.t('contractsEnums:TitleEnum.DR'),
    value: TitleEnum.DR
  },
  {
    label: i18n.t('contractsEnums:TitleEnum.PROF'),
    value: TitleEnum.PROF
  },
  {
    label: i18n.t('contractsEnums:TitleEnum.PROF_DR'),
    value: TitleEnum.PROF_DR
  }
];

export const relationshipToHolderOptions: Array<DropdownOption> = [
  {
    value: RelationshipToHolderEnum.PARTNER,
    label: i18n.t('contractsEnums:RelationshipToHolderEnum.PARTNER')
  },
  {
    value: RelationshipToHolderEnum.CHILDREN,
    label: i18n.t('contractsEnums:RelationshipToHolderEnum.CHILDREN')
  },
  {
    value: RelationshipToHolderEnum.GRAND_CHILDREN,
    label: i18n.t('contractsEnums:RelationshipToHolderEnum.GRAND_CHILDREN')
  },
  {
    value: RelationshipToHolderEnum.NONE,
    label: i18n.t('contractsEnums:RelationshipToHolderEnum.NONE')
  }
];

export const maritalStatusOptions: Array<DropdownOption> = [
  {
    value: MaritalStatusEnum.MARRIED,
    label: i18n.t('contractsEnums:MaritalStatusEnum.MARRIED')
  },
  {
    value: MaritalStatusEnum.PARTNERED,
    label: i18n.t('contractsEnums:MaritalStatusEnum.PARTNERED')
  },
  {
    value: MaritalStatusEnum.SINGLE,
    label: i18n.t('contractsEnums:MaritalStatusEnum.SINGLE')
  },
  {
    value: MaritalStatusEnum.WIDOWED,
    label: i18n.t('contractsEnums:MaritalStatusEnum.WIDOWED')
  }
];
