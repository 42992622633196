import { ReactNode } from 'react';
import { Caption, Label } from 'lkh-portal-ui-library';

import styles from './SimpleTable.module.scss';
import classNames from 'classnames';

export type SimpleTableProps = {
  title?: string;
  titleClass?: string;
  rows: Array<{
    label: string;
    value: string | number | ReactNode;
  }>;
};

export const SimpleTable = ({ rows, title, titleClass }: SimpleTableProps) => {
  return (
    <>
      {title && (
        <Caption bold className={classNames('m-b-25', titleClass)}>
          {title}
        </Caption>
      )}
      <div className={styles.simpleTable}>
        {rows.map(({ label, value }) => (
          <div key={`${label}-${value}`} className={styles.row}>
            <div className={styles.colHeading}>
              <Label bold>{label}</Label>
            </div>
            <div className={styles.colData}>
              <Label regular>{value}</Label>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
