import {
  ProgressBar,
  QuestionsContextProvider,
  formModelGet,
  useApplicationHelpers
} from 'lkh-portal-ui-library';
import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { WizardLayout } from 'layout/wizard';
import { useURLParams } from 'hooks/useURLParams';
import { WizardScreen } from './WizardRouter';
import { WizardRoutesEnum } from 'utils/actions';
import { WizardSlot } from 'layout/wizard/slots';
import { Loading } from 'components/Loading';
import {
  HealthInsuranceContextProvider,
  useHealthInsuranceContext
} from 'contexts/HealthInsuranceContext';
import { WizardNavigationContextProvider, useWizardNavigation } from 'hooks/useWizardNavigation';

import { Tariff } from 'models/extension-generated';
import { partnerField } from 'models';

import { Sidebar } from 'components/Sidebar';
import { TariffContextProvider } from 'contexts/TariffSectionContext';
import { WhiteLogo } from 'components/Logo';
import { getQuestionsByCriteria } from 'utils/getQuestionsByCriteria';

function handleProgressBarChange(index: number, goTo: (screen: WizardRoutesEnum) => void) {
  switch (index) {
    case 0:
      goTo(WizardRoutesEnum.PrivateData);
      return;
    case 1:
      goTo(WizardRoutesEnum.InsuranceHolder);
      return;
    case 2:
      goTo(WizardRoutesEnum.Summary);
      return;
  }
}

export function Content() {
  const { t } = useTranslation('wizardPrivateHealthInsurance');
  useURLParams();
  const isLoading = false;
  const { currentScreen, currentSection, completeSections, goTo } = useWizardNavigation();
  const { state, reducer, partnerId } = useHealthInsuranceContext();
  const { getPartnerById } = useApplicationHelpers(state.model);

  const tariffsPath = partnerField(
    partnerId,
    'applicationInformation.tariffInformation.selectedTariffs'
  );
  const selectedTariffs: Array<Tariff> = formModelGet(state.model, tariffsPath) || [];
  const currentPartner = getPartnerById(partnerId);
  const birthDate = currentPartner.birthDate;
  const insuranceStart = state.model.applicationStart || '';

  const isOnInsuredPersonScreen = currentScreen === WizardRoutesEnum.PrivateData;

  const fetchQuestionsFn = async ({
    tariffs,
    birthDate,
    insuranceStart
  }: {
    tariffs: Array<Tariff>;
    birthDate: string;
    insuranceStart: string;
  }) => {
    const questions = await getQuestionsByCriteria({
      tariffs,
      hasBirthCriteria: true,
      birthDate,
      insuranceStart
    });
    if (isOnInsuredPersonScreen) {
      reducer.createQuestions({
        healthKey: partnerField(partnerId, 'applicationInformation.health'),
        healthQuestions: questions
      });
    }
    return questions;
  };

  const progressSteps = useMemo(
    () => [
      { step: 1, label: t('navigation.steps.insuredPeople') },
      { step: 2, label: t('navigation.steps.holder') },
      { step: 3, label: t('navigation.steps.summary') }
    ],
    []
  );

  return (
    <WizardLayout>
      <QuestionsContextProvider
        getQuestionsByCriteria={fetchQuestionsFn}
        tariffs={selectedTariffs}
        birthDate={isOnInsuredPersonScreen ? birthDate : ''}
        insuranceStart={isOnInsuredPersonScreen ? insuranceStart : ''}
      >
        <WizardSlot.TopNavigation>
          <ProgressBar
            stepList={progressSteps}
            completeList={completeSections}
            active={currentSection}
            rightContent={
              <div className="h-[28px]">
                <WhiteLogo />
              </div>
            }
            onSelectStep={(step) => handleProgressBarChange(step, goTo)}
          />
        </WizardSlot.TopNavigation>

        <WizardSlot.RightPanel>
          <Sidebar />
        </WizardSlot.RightPanel>

        {isLoading ? <Loading /> : <WizardScreen currentScreen={currentScreen} />}
      </QuestionsContextProvider>
    </WizardLayout>
  );
}

export const DigitalOrderPage = () => {
  return (
    <WizardNavigationContextProvider>
      <HealthInsuranceContextProvider>
        <TariffContextProvider>
          <Content />
        </TariffContextProvider>
      </HealthInsuranceContextProvider>
    </WizardNavigationContextProvider>
  );
};
