import { useMemo, useState } from 'react';
import classNames from 'classnames';
import {
  Body,
  Button,
  customizeTheme,
  DeleteBinIcon,
  Icon,
  MaterialAccordion,
  NeutralButtonTheme,
  Props,
  useApplicationHelpers
} from 'lkh-portal-ui-library';

import { Partner, PersonRoleEnum, TariffCalculationResult } from 'models/extension-generated';

import { formatPrice } from 'utils/string';

import { useApplicationValidation } from '../../hooks/useApplicationValidation';
import { TariffRow } from './TariffRow/TariffRow';
import { useHealthInsuranceContext } from 'contexts/HealthInsuranceContext';
import { useTariffQuery } from 'hooks/useTariffQuery';
import { useWizardNavigation } from 'hooks/useWizardNavigation';
import { WizardRoutesEnum } from 'utils/actions';

type PartnerSummaryProps = {
  partner: Partner;
  isLoading: boolean;
  isMobile: boolean;
  price: number;
  tariffs: Array<TariffCalculationResult>;
};

export const PartnerSummary = ({
  partner,
  price,
  tariffs,
  isMobile,
  isLoading = false
}: PartnerSummaryProps) => {
  const { state, partnerId, selectPartner, reducer } = useHealthInsuranceContext();
  const { currentScreen } = useWizardNavigation();
  const { clearRole, createPartner } = reducer;
  const { isValidating } = useApplicationValidation(state, reducer, partnerId);
  const { getPartnersByRole } = useApplicationHelpers(state.model);
  const { tariffs: allTariffsList } = useTariffQuery(partnerId);
  const insuredPartners = useMemo(() => getPartnersByRole(PersonRoleEnum.INSURED_PERSON), [state]);
  const isSelected = useMemo(() => partnerId === partner.id!, [partnerId]);

  function handleDeletePartner(): void {
    const currentRoles = partner.roles || [];
    if (isSelected) {
      const prevIndex = insuredPartners.findIndex((p) => p.id === partnerId) - 1;
      selectPartner(insuredPartners[prevIndex].id!);
    }

    if (currentRoles.includes(PersonRoleEnum.POLICY_HOLDER)) {
      clearRole({ role: PersonRoleEnum.POLICY_HOLDER });
      createPartner({ role: PersonRoleEnum.POLICY_HOLDER });
    }
    reducer.removePartner({ id: partner.id! });
  }

  const isRemoveDisabled = useMemo(() => {
    return (
      isValidating || insuredPartners.length === 1 || currentScreen !== WizardRoutesEnum.PrivateData
    );
  }, [state]);

  const textColorClassname = isMobile ? 'text-white' : '';
  const selectedTariffs = partner.applicationInformation?.tariffInformation?.selectedTariffs || [];
  return (
    <>
      <hr className="m-0" />

      <MaterialAccordion
        summaryProps={{
          sx: {
            backgroundColor: isMobile ? '#00635f' : 'transparent'
          }
        }}
        accordionDetailsProps={{
          sx: {
            backgroundColor: isMobile ? '#00635f' : 'transparent'
          }
        }}
        accordionProps={{
          sx: {
            boxShadow: 'none !important',
            border: 'none',
            backgroundColor: isMobile ? '#00635f' : 'transparent'
          }
        }}
        header={
          <div className={`flex justify-between items-center w-full ${textColorClassname}`}>
            <Body
              small
              className={classNames('flex items-center', {
                'text-white': isMobile,
                'text-primary': !isMobile
              })}
            >
              {partner.lastname && <>{partner.lastname}</>}
              {partner.lastname && partner.firstname && <>,&nbsp;</>}
              {partner.firstname && <>{partner.firstname}</>}
            </Body>
            <div>
              {!isLoading && (
                <Body small bold className={textColorClassname}>
                  {price ? formatPrice(price) : '-,--'}
                </Body>
              )}
              <Button
                size={Props.Size.Small}
                onClick={handleDeletePartner}
                disabled={isRemoveDisabled}
                theme={customizeTheme(NeutralButtonTheme, 'color', Props.StatusColors.Error.Base)}
              >
                <Icon icon={<DeleteBinIcon />} className="" />
              </Button>
            </div>
          </div>
        }
      >
        <div className="p-h-10 space-y-4">
          {selectedTariffs
            .sort((a, b) => (a.sequenceOfOrder! > b.sequenceOfOrder! ? 1 : -1))
            .map((tariff) => {
              const calculationTariff = tariffs.find(({ tariffId }) => tariffId === tariff.id);

              return (
                <TariffRow
                  isMobile={isMobile}
                  key={`partner-summary-${tariff.id}`}
                  calculation={calculationTariff}
                  tariff={tariff}
                  tariffsList={allTariffsList}
                />
              );
            })}
          {selectedTariffs.length === 0 && <Body small>Kein Tarif ausgewählt</Body>}
        </div>
      </MaterialAccordion>
    </>
  );
};
