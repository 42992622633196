import { Fragment } from 'react';
import { Caption, Label } from 'lkh-portal-ui-library';
import { useTranslation } from 'react-i18next';
import { formatGermanDate } from 'utils/dates';
import { formatBoolean, optionalValue } from 'utils/formatters';
import { HorizontalSeparator } from '../HorizontalSeparator/HorizontalSeparator';
import { HealthQuestionsProps } from 'pages/DigitalOrderPage/models/insuredPersonDetailModels';

export const HealthQuestions = ({ questions }: HealthQuestionsProps) => {
  const { t } = useTranslation('view360', { keyPrefix: 'insuredPerson.health' });

  return (
    <>
      {(questions || []).map((question) => {
        const renderQuestion = () => (
          <div className="row  my-s">
            <div className="col">
              <Label bold>{question.text}</Label>
            </div>
            <div className="col">
              <Label regular>{question.answer}</Label>
            </div>
          </div>
        );

        const renderDetails = () => (
          <>
            {(question.details || []).map((detail, index) => (
              <div className="row m-v-16 p-16 bg-background" key={detail.diagnosis}>
                <div className="col reset-container">
                  <div className="row m-b-8">
                    <div className="col">
                      <Label bold>{`${t('questions.detail')} ${index + 1}`}</Label>
                    </div>
                  </div>
                  <div className="row m-b-8">
                    <div className="col-6">
                      <Label regular>{t('questions.attributes.diagnose')}</Label>
                    </div>
                    <div className="col-6">
                      <Label regular>{detail.diagnosis}</Label>
                    </div>
                  </div>
                  <div className="row m-b-8">
                    <div className="col-6">
                      <Label regular>{t('questions.attributes.treatmentType')}</Label>
                    </div>
                    <div className="col-6">
                      <Label regular>{optionalValue(detail.treatmentType)}</Label>
                    </div>
                  </div>
                  <div className="row m-b-8">
                    <div className="col-6">
                      <Label regular>{t('questions.attributes.treatmentDuration')}</Label>
                    </div>
                    <div className="col-6">
                      <Label regular>{`${optionalValue(
                        detail.treatmentStart,
                        formatGermanDate
                      )} - ${optionalValue(detail.treatmentEnd, formatGermanDate)}`}</Label>
                    </div>
                  </div>
                  <div className="row m-b-8">
                    <div className="col-6">
                      <Label regular>{t('questions.attributes.operation')}</Label>
                    </div>
                    <div className="col-6">
                      <Label regular>{optionalValue(detail.hasOperation, formatBoolean)}</Label>
                    </div>
                  </div>
                  <div className="row m-b-8">
                    <div className="col-6">
                      <Label regular>{t('questions.attributes.consenquences')}</Label>
                    </div>
                    <div className="col-6">
                      <Label regular>{optionalValue(detail.hasConsenquences, formatBoolean)}</Label>
                    </div>
                  </div>
                  <div className="row m-b-8">
                    <div className="col-6">
                      <Label regular>{t('questions.attributes.consenquencesDate')}</Label>
                    </div>
                    <div className="col-6">
                      <Label regular>
                        {optionalValue(detail.symptomsFreeFrom, formatGermanDate)}
                      </Label>
                    </div>
                  </div>
                  <div className="row m-b-8">
                    <div className="col-6">
                      <Label regular>{t('questions.attributes.operation')}</Label>
                    </div>
                    <div className="col-6">
                      <Label regular>{optionalValue(detail.hasOperation, formatBoolean)}</Label>
                    </div>
                  </div>
                  <div className="row m-b-8">
                    <div className="col-6">
                      <Label regular>{t('questions.attributes.operationDate')}</Label>
                    </div>
                    <div className="col-6">
                      <Label regular>{optionalValue(detail.operationDate, formatGermanDate)}</Label>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        );

        return (
          <div key={question.id}>
            {renderQuestion()}
            {renderDetails()}
            <HorizontalSeparator className="m-v-16" />
          </div>
        );
      })}
    </>
  );
};
