import { PrimaryButton, Loader, LoaderType, Body, formModelSet } from 'lkh-portal-ui-library';
import cloneDeep from 'lodash/cloneDeep';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWizardNavigation } from '../../../hooks/useWizardNavigation';
import { toast } from 'react-toastify';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { useNavigate } from 'react-router';

import styles from './ContinueButton.module.scss';
import { WizardRoutesEnum } from 'utils/actions';
import { useHealthInsuranceContext } from 'contexts/HealthInsuranceContext';
import { useApplicationValidation } from 'hooks/useApplicationValidation';
import { ApplicationService, NeedSituationEnum, PersonRoleEnum } from 'models/extension-generated';
import { partnerField } from 'models';
import { EVENT_IDS } from 'pages/DigitalOrderPage/constants';

type ContinueButtonProps = {
  title: string;
  isIcon: boolean;
};

export const ContinueButton = ({ title }: ContinueButtonProps) => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation('wizardPrivateHealthInsurance');
  const { next, ScreenOrder, currentScreen } = useWizardNavigation();
  const { reducer, state, partnerId } = useHealthInsuranceContext();

  const { isValidating, validateNextPage } = useApplicationValidation(state, reducer, partnerId);

  const handleContinueClick = async () => {
    try {
      const index = ScreenOrder.indexOf(currentScreen) + 1;
      const nextPage = ScreenOrder[index];
      const validationResult = await validateNextPage(nextPage);
      const hasNoErrors = validationResult.size == 0;

      if (hasNoErrors) {
        // if current screen is summary screen then submit application
        if (currentScreen === WizardRoutesEnum.Summary) {
          setIsSubmitting(true);
          const cloned = { ...cloneDeep(state.model) };
          const insuredPersons = cloned.partners.filter(({ roles }) =>
            roles.includes(PersonRoleEnum.INSURED_PERSON)
          );
          insuredPersons.forEach(({ id }) => {
            const needSituationPath = partnerField(
              id || '',
              'applicationInformation.tariffInformation.needSituation'
            );
            formModelSet(cloned, needSituationPath, NeedSituationEnum.ZVGKV);
          });

          await ApplicationService.submitPublicApplication({
            requestBody: cloned
          });
          navigate(`/${WizardRoutesEnum.Done}/${state.model.id}`);
          return;
        }
        next();
      }
    } catch (e: any) {
      if (e?.message) {
        toast.warning(e.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isSubmitting || isValidating) {
    return (
      <PrimaryButton disabled className="w-full s:w-auto flex justify-center">
        <div className={styles.loadingButton}>
          <Body small medium className="m-r-8">
            {isSubmitting && t('actions.submitting')}
            {isValidating && t('actions.validating')}
          </Body>
          <Loader className={styles.loader} type={LoaderType.Circular} />
        </div>
      </PrimaryButton>
    );
  }

  return (
    <>
      <PrimaryButton
        id={`${currentScreen}_${EVENT_IDS.NEXT}`}
        className="w-full s:w-auto flex justify-center"
        onClick={handleContinueClick}
      >
        <span className="tracking-normal text-[14px] font-[700] leading-[20px] pr-[8px]">
          {title}
        </span>
        <ArrowForwardOutlinedIcon className="text-primary" />
      </PrimaryButton>
    </>
  );
};
