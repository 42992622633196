import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';

declare const cc: any;

const handleShowCookies = (): void => {
  cc.show();
};

function FooterDivider() {
  return <Divider sx={{ height: 16, bgcolor: 'white', ml: 1, mr: 1 }} orientation="vertical" />;
}

export function LegalFooter() {
  const { t } = useTranslation('footer');

  return (
    <div
      className={
        'bg-lkh-color-tertiary-progress-100 flex flex-row items-center w-full h-[64px] s:h-[32px] z-100 py-[2px]'
      }
    >
      <div className="s:pl-[40px] m:pl-[80px] pl-[0px] s:pr-[40px] m:pr-[80px] pr-[0px] flex flex-col s:flex-row items-center w-full">
        <div className="flex-1">
          <div className="flex flex-row items-center h-[24px] text-[10px] font-normal leading-4 tracking-tight">
            <p>
              <a href="https://www.lkh.de/impressum" className="text-white" target="_blank">
                {t('imprint')}
              </a>
            </p>
            <FooterDivider />
            <p>
              <a href="https://www.lkh.de/datenschutz#cc" className="text-white" target="_blank">
                {t('privacy')}
              </a>
            </p>
            <FooterDivider />
            <p>
              <a href="#" className="text-white" onClick={handleShowCookies}>
                {t('cookies')}
              </a>
            </p>
          </div>
        </div>
        <div>
          <p className="text-[10px] font-normal leading-4 tracking-tight text-white">{t('name')}</p>
        </div>
      </div>
    </div>
  );
}
