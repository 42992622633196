import Heading from 'components/Heading';
import {
  Form,
  GenderForm,
  BirthDateForm,
  MaritalStatusForm,
  processText
} from 'lkh-portal-ui-library';
import { PartnerFormReducers, PartnerIdProp } from 'lkh-portal-ui-library/dist/models';
import { useTranslation } from 'react-i18next';
import { genderOptions, maritalStatusOptions, titleOptions } from 'utils/dropdowns';

const { Input, Dropdown } = Form.Components;

export type InsuredPersonFormProps = PartnerIdProp & {
  partnerKeys: {
    genderKey: string;
    salutationKey: string;
    titleKey: string;
    firstNameKey: string;
    lastNameKey: string;
    birthDateKey: string;
    fundsFromGZKey: string;
    maritalStatusKey: string;
  };
  reducer: Pick<PartnerFormReducers, 'updateValue'>;
  hasBirthDate?: boolean;
  hasTitle?: boolean;
  hasNobility?: boolean;
  hasMaritalStatus?: boolean;
  text?: {
    title?: string;
    firstName: {
      label: string;
      placeholder?: string | undefined;
    };
    lastName: {
      label: string;
      placeholder?: string | undefined;
    };
  };
  disabled?: boolean;
};

export function InsuredPersonForm({
  partnerId,
  partnerKeys,
  reducer: { updateValue },
  hasTitle,
  hasBirthDate,
  hasMaritalStatus,
  text = {
    title: '',
    firstName: {
      label: '',
      placeholder: ''
    },
    lastName: {
      label: '',
      placeholder: ''
    }
  },
  disabled = false
}: InsuredPersonFormProps) {
  const { t } = useTranslation('InsuredPersonForm');

  const {
    genderKey,
    salutationKey,
    titleKey,
    firstNameKey,
    lastNameKey,
    birthDateKey,
    fundsFromGZKey,
    maritalStatusKey
  } = partnerKeys;

  return (
    <>
      <div className="grid-mx-res">
        <Heading level={3}>{processText(text.title, t('section.title'))}</Heading>
      </div>
      <Form.Section>
        <div className="grid-res-2">
          <div>
            <GenderForm
              genderKey={genderKey}
              salutationKey={salutationKey}
              options={genderOptions}
              disabled={disabled}
              updateValue={updateValue}
            />
          </div>
          {hasBirthDate && (
            <div>
              <BirthDateForm
                partnerId={partnerId}
                label={t('fields.birthDate.label')}
                birthDateKey={birthDateKey}
                fundsFromGZKey={fundsFromGZKey}
                disabled={disabled}
                updateValue={updateValue}
              />
            </div>
          )}
        </div>

        {hasTitle && (
          <div className="grid-res-2">
            {hasTitle && (
              <div>
                <Dropdown
                  componentKey={titleKey}
                  label={t('fields.title.label')}
                  placeholder={t('fields.title.placeholder')}
                  disabled={disabled}
                  options={titleOptions}
                  unsetOption={{
                    label: t('contractsEnums:None'),
                    value: '',
                    type: 'option'
                  }}
                />
              </div>
            )}
          </div>
        )}

        <div className="grid-res-2">
          <div>
            <Input
              componentKey={firstNameKey}
              label={processText(text.firstName.label, t('fields.firstName.placeholder'))}
              disabled={disabled}
            />
          </div>
          <div>
            <Input
              componentKey={lastNameKey}
              label={processText(text.lastName.label, t('fields.lastName.placeholder'))}
              disabled={disabled}
            />
          </div>
        </div>
        {hasMaritalStatus && (
          <div className="grid-res-2">
            <div>
              <MaritalStatusForm
                partnerId={partnerId}
                maritalStatusOptions={maritalStatusOptions}
                text={{
                  label: t('fields.maritalStatus.label'),
                  placeholder: t('fields.maritalStatus.placeholder')
                }}
                maritalStatusKey={maritalStatusKey}
              />
            </div>
          </div>
        )}
      </Form.Section>
    </>
  );
}
