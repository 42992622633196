import { useTranslation } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import {
  formModelGet,
  useApplicationHelpers,
  useQuestionsContext,
  Icon,
  UserCircleIcon,
  Accordion,
  Body,
  Form,
  Label
} from 'lkh-portal-ui-library';
import groupBy from 'lodash/groupBy';
import { useHealthInsuranceContext } from 'contexts/HealthInsuranceContext';
import { WizardSlot } from 'layout/wizard/slots';
import { partnerField } from 'models';
import Markdown from 'react-markdown';
import { downloadData, downloadDisclaimerDocument } from 'utils/fetchDocument';

import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { NavigationSection } from 'pages/DigitalOrderPage/components/NavigationSection/NavigationSection';
import { AccompaniedDocumentsDownload } from './components/AccompaniedDocumentsDownload';
import axios from 'axios';
import Heading from 'components/Heading';
import { DownloadOfferDialog } from 'components/DownloadButton/DownloadOfferDialog';
import {
  Partner,
  PersonRoleEnum,
  ApplicationService,
  Question,
  TariffSectionEnum
} from 'models/extension-generated';
import { InsuredPersonDetail } from 'pages/DigitalOrderPage/components/InsuredPersonDetail/InsuredPersonDetail';
import { PolicyHolderDetail } from 'pages/DigitalOrderPage/components/PolicyHolderDetail/PolicyHolderDetail';
import { PaymentContributorDetail } from 'pages/DigitalOrderPage/components/PaymentContributorDetial/PaymentContributorDetail';
import styles from './index.module.scss';
import {
  HealthQuestionsProps,
  InsuredPersonDetailProps
} from 'pages/DigitalOrderPage/models/insuredPersonDetailModels';
import { useApplicationCalculate } from 'hooks/useApplicationCalculate';
import { formatGermanDate } from 'utils/dates';
import { formatPriceAsText } from 'utils/string';
const { Checkbox } = Form.Components;
import {
  OfferTariffTable,
  TariffRow,
  Cells
} from 'pages/DigitalOrderPage/components/OfferTariffTable';
import classNames from 'classnames';
import {
  optionalValue,
  formatEnum,
  formatPercent,
  formatAddress,
  formatName
} from 'utils/formatters';
import { useTariffQuery } from 'hooks/useTariffQuery';
import { getQuestionsByCriteria } from 'utils/getQuestionsByCriteria';
import { LegalFooter } from 'components/LegalFooter';
import { mapHealthQuestion } from './utils/mapHealthQuestion';
import { EVENT_IDS } from 'pages/DigitalOrderPage/constants';

const fetchPartnerQuestions = (insuranceStart: string) => async (partner: Partner) => {
  const tariffs = partner.applicationInformation?.tariffInformation?.selectedTariffs || [];
  const birthDate = partner.birthDate;
  const questions = await getQuestionsByCriteria({ tariffs, insuranceStart, birthDate });

  if (!partner.id)
    return {
      partnerId: '',
      questions: []
    };

  return {
    partnerId: partner.id,
    questions
  };
};

const usePartnersQuestions = (
  partners: Array<Partner>,
  insuranceStart: string
): {
  isLoading: boolean;
  partnersQuestions: {
    [key: string]: Array<Question>;
  };
} => {
  const [isLoading, setIsLoading] = useState(false);
  const [partnersQuestions, setPartnersQuestions] = useState<{
    [key: string]: Array<Question>;
  }>({});

  useEffect(() => {
    setIsLoading(true);

    const fetchQuestionsForPartners = async (partners: Array<Partner>) => {
      try {
        const partnersWithQuestions: Array<{ partnerId: string; questions: Array<Question> }> =
          await Promise.all(partners.map(fetchPartnerQuestions(insuranceStart)));

        const obj = partnersWithQuestions.reduce((acc, { partnerId, questions }) => {
          return {
            ...acc,
            [partnerId]: questions
          };
        }, {});
        setPartnersQuestions(obj);
      } finally {
        setIsLoading(false);
      }
    };

    fetchQuestionsForPartners(partners);
  }, []);

  return {
    isLoading,
    partnersQuestions: partnersQuestions
  };
};

// TODO refactor to smaller components
export const SummaryStep = () => {
  const { t } = useTranslation('summaryPage');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { state, reducer, dispatch } = useHealthInsuranceContext();
  const { getSinglePartnerByRole, getPartnersByRole } = useApplicationHelpers(state.model);
  const { questions } = useQuestionsContext();
  const { data } = useApplicationCalculate(state.model, questions, true);
  const insuredPartners = getPartnersByRole(PersonRoleEnum.INSURED_PERSON);
  const insuranceStart = state.model.applicationStart || '';

  const { partnersQuestions } = usePartnersQuestions(insuredPartners, insuranceStart);

  const insuredPersons: Array<Partner> = getPartnersByRole(PersonRoleEnum.INSURED_PERSON)
    .sort((a, b) => (a.order! > b.order! ? 1 : -1))
    .map((p, i) => ({ ...p, order: 1 * (i + 1) }));
  const policyHolder = getSinglePartnerByRole(PersonRoleEnum.POLICY_HOLDER);
  const paymentContributor = getSinglePartnerByRole(PersonRoleEnum.PAYMENT_CONTRIBUTOR);

  const paymentContributorResidence = paymentContributor.permanentResidence;
  const paymentContributorAccount = paymentContributor.bankDetails;

  const formatMarketingStreams = (partner: Partner): string => {
    const streams = [];
    if (partner.applicationInformation?.acceptsPromotionalPurposesEmail) {
      streams.push(t('utils.email'));
    }
    if (partner.applicationInformation?.acceptsPromotionalPurposesPhone) {
      streams.push(t('utils.phone'));
    }

    return streams.length > 0 ? streams.join(', ') : '-';
  };

  const { id: holderId = '' } = getSinglePartnerByRole(PersonRoleEnum.POLICY_HOLDER);

  const hasConsentPath = partnerField(holderId, 'applicationInformation.acceptsDataProtection');
  const hasDownloadedContractInformationDocumentPath = partnerField(
    holderId,
    'applicationInformation.hasDownloadedContractInformationDocument'
  );
  const hasConsent = formModelGet(state.model, hasConsentPath);
  const downloadFile = async (): Promise<void> => {
    try {
      const cloned = { ...cloneDeep(state.model) };
      const applicationId: string = await (async () => {
        if (cloned.id) {
          return cloned.id;
        }
        const { id } = await ApplicationService.createPublicApplication({ requestBody: cloned });
        reducer.updateValue({
          key: 'id',
          value: id
        });
        return id;
      })();

      const response = await axios.post(
        `/api/public/new/application/accompanyDocs`,
        {
          ...cloned,
          id: applicationId
        },
        {
          responseType: 'blob'
        }
      );

      downloadData(response.data, `Vertragsinformationen.pdf`);

      reducer.updateValue({
        key: hasDownloadedContractInformationDocumentPath,
        value: true
      });
      setIsDialogOpen(false);
    } catch (error) {
      throw new Error(`Error occurred during download: ${error}`);
    }
  };

  const downloadDisclaimer = async () => {
    try {
      await downloadDisclaimerDocument(state.model.applicationStart);
    } catch (error) {
      throw new Error(`Error occurred during download: ${error}`);
    }
  };

  const handleConsentClick = (hasConsent: boolean) => {
    reducer.updateValue({
      key: hasConsentPath,
      value: hasConsent
    });
  };

  const sendMail = () => {
    window.location.href = 'mailto:vertriebsservice@lkh.de';
  };

  const { tariffs } = useTariffQuery();

  const renderInsuredPersons = () =>
    insuredPersons.map((partner) => {
      if (!partner.id) return <></>;

      const selectedTariffs =
        partner.applicationInformation?.tariffInformation?.selectedTariffs || [];
      const partnerQuestions = partnersQuestions[partner.id] || [];
      const groupedQuestions = groupBy(partnerQuestions, 'sections');

      const stationaryHealthQuestions: HealthQuestionsProps = {
        questions: groupedQuestions[TariffSectionEnum.STATIONAER]?.map(mapHealthQuestion(partner))
      };
      const teethHealthQuestions: HealthQuestionsProps = {
        questions: groupedQuestions[TariffSectionEnum.ZAHN]?.map(mapHealthQuestion(partner))
      };

      const mappedPartner: InsuredPersonDetailProps = {
        order: partner.order || 0,
        tariffTable: (
          <>
            <OfferTariffTable
              allowEditing={false}
              summaryRow={{
                id: 'sampleId',
                product: <Label bold>{t('monthlyPayment')}</Label>,
                price: (
                  <Cells.Price
                    text={formatPriceAsText(
                      data?.partners.find(({ partnerId }: any) => partnerId === partner.id)
                        ?.calculationMonthly?.totalAmount
                    )}
                  />
                )
              }}
              tariffs={selectedTariffs.map((tariff) => {
                const { section, id } = tariff;

                const description = tariffs.find((t) => {
                  return t.id === id;
                })?.description;

                const tariffPrice = data?.partners
                  .find(({ partnerId }: any) => partnerId === partner.id)
                  ?.tariffCalculations?.find((t) => {
                    return t.tariffId === id;
                  })?.calculationMonthly;

                const tariffRow: TariffRow = {
                  id: id,
                  product: (
                    <Cells.ProductLabel
                      label={t(`TariffSectionEnum.${section}`, {
                        ns: 'contractsEnums'
                      })}
                    />
                  ),
                  label: <Cells.TariffLabel label={id} tooltip={description || ''} />,
                  price: <Cells.Price text={formatPriceAsText(tariffPrice?.amount)} />
                };
                return tariffRow;
              })}
            />
          </>
        ),
        stationaryHealthQuestions,
        teethHealthQuestions,
        firstName: partner.firstname,
        isPolicyHolder: policyHolder.id === partner.id,
        // [Personal data]
        name: optionalValue(partner, formatName) as string,
        lastName: partner.lastname,
        birthDate: partner.birthDate,
        address: formatAddress(partner.permanentResidence),
        salutation: formatEnum(partner?.salutation, 'SalutationEnum', 'contractsEnums'),
        academicTitle: formatEnum(partner.title, 'TitleEnum', 'contractsEnums'),
        maritalStatus: formatEnum(partner.maritalStatus, 'MaritalStatusEnum', 'contractsEnums'),
        // nationality: ??? [NOT IN DTO]
        // [Employment Data]
        professionalPosition: formatEnum(
          partner.applicationInformation?.employmentGroup,
          'EmploymentGroupEnum',
          'contractsEnums'
        ),
        currentOccupation: formatEnum(
          partner?.applicationInformation?.profession,
          'ProfessionEnum',
          'contractsEnums'
        ),
        employer: partner.applicationInformation?.employer,
        trainingStart: partner.applicationInformation?.trainingStart,
        trainingEnd: partner.applicationInformation?.trainingEnd,
        isLivingInGermany: partner.livingInGermany,
        street: partner.permanentResidence.street,
        houseNumber: partner.permanentResidence.houseNumber,
        postalCode: partner.permanentResidence.postalCode,
        city: partner.permanentResidence.city,
        country: partner.permanentResidence.country,
        hasForeignResidence: partner.hasLivedInForeignCountry,
        foreignCountry: partner.foreignCountry,
        taxNumber: partner?.taxNumber,
        needSituation: formatEnum(
          partner.applicationInformation?.tariffInformation?.needSituation,
          'NeedSituationEnum',
          'contractsEnums'
        ),
        insuranceStart: state.model.applicationStart,
        federalState: formatEnum(
          partner.applicationInformation?.federalState,
          'FederalStateEnum',
          'contractsEnums'
        ),
        aidClaim: optionalValue(
          partner?.applicationInformation?.tariffInformation?.claimAmount,
          formatPercent
        ) as string,
        hadInsuranceSince2012: partner?.applicationInformation?.tariffInformation?.hasClaimSince2012
      };

      return (
        <div className="mb-[24px]">
          <div className={classNames(styles.customer, 'mb-[10px]')}>
            <div className="flex justify-between mb-[24px]">
              <div className={styles.user}>
                <Icon
                  icon={<UserCircleIcon />}
                  size={'xl'}
                  className={`${styles.icon} mr-[10px]`}
                />
                <Body small bold>
                  {partner.lastname}, {partner.firstname}
                </Body>
              </div>
              <div className="flex flex-col">
                <Body small bold className="flex justify-end">
                  {formatGermanDate(state.model.applicationStart || '')}
                </Body>
                <Label className="text-text60">{t('insuranceStart')}</Label>
              </div>
            </div>
            <Accordion header={<Label bold>{t('details')}</Label>} headerClassName="flex">
              <div className="flex justify-between">
                <InsuredPersonDetail {...mappedPartner} />
              </div>
            </Accordion>
          </div>
          {mappedPartner.tariffTable}
        </div>
      );
    });

  const renderPolicyHolder = () => {
    const policyHolderMapped = {
      // [Personal Data]
      name: optionalValue(policyHolder, formatName) as string,
      firstName: policyHolder.firstname,
      lastName: policyHolder.lastname,
      birthDate: policyHolder.birthDate,
      address: formatAddress(policyHolder.permanentResidence),
      salutation: formatEnum(policyHolder?.salutation, 'SalutationEnum', 'contractsEnums'),
      academicTitle: formatEnum(policyHolder.title, 'TitleEnum', 'contractsEnums'),
      maritalStatus: formatEnum(policyHolder.maritalStatus, 'MaritalStatusEnum', 'contractsEnums'),
      // [Employment Data]
      professionalPosition: formatEnum(
        policyHolder.applicationInformation?.employmentGroup,
        'EmploymentGroupEnum',
        'contractsEnums'
      ),
      currentOccupation: formatEnum(
        policyHolder?.applicationInformation?.profession,
        'ProfessionEnum',
        'contractsEnums'
      ),
      employer: policyHolder.applicationInformation?.employer,
      // [Address]
      isLivingInGermany: policyHolder.livingInGermany,
      street: policyHolder.permanentResidence.street,
      houseNumber: policyHolder.permanentResidence.houseNumber,
      postalCode: policyHolder.permanentResidence.postalCode,
      city: policyHolder.permanentResidence.city,
      country: policyHolder.permanentResidence.country,
      hasForeignResidence: policyHolder.hasLivedInForeignCountry,
      foreignCountry: policyHolder.foreignCountry,
      // [Contact]
      email: policyHolder.permanentResidence.email,
      phone: policyHolder.permanentResidence.phone,
      acceptedMarketingStreams: optionalValue(policyHolder, formatMarketingStreams) as string
    };
    return (
      <div className={classNames(styles.customer, 'mb-[24px]')}>
        <div className="flex justify-between">
          <div className="mb-[24px]">
            <div className={styles.user}>
              <Body small bold>
                {policyHolder.lastname}, {policyHolder.firstname}
              </Body>
            </div>
            <div>
              <Label className="text-text60">{t('insuredPerson')}</Label>
            </div>
          </div>
        </div>
        <Accordion header={<Label bold>{t('details')}</Label>}>
          <div className="flex justify-between">
            <PolicyHolderDetail key={policyHolder.firstname} {...policyHolderMapped} />
          </div>
        </Accordion>
      </div>
    );
  };

  const renderPaymentContributor = () => {
    const mappedPaymentContributor = {
      // [Personal Data]
      name: optionalValue(paymentContributor, formatName) as string,
      firstName: paymentContributor?.firstname,
      lastName: paymentContributor?.lastname,
      birthDate: paymentContributor?.birthDate,
      address: formatAddress(paymentContributorResidence),
      salutation: formatEnum(paymentContributor?.salutation, 'SalutationEnum', 'contractsEnums'),
      academicTitle: formatEnum(policyHolder.title, 'TitleEnum', 'contractsEnums'),
      // [Address]
      street: paymentContributorResidence?.street,
      houseNumber: paymentContributorResidence?.houseNumber,
      postalCode: paymentContributorResidence?.postalCode,
      city: paymentContributorResidence?.city,
      country: paymentContributorResidence?.country,
      // hasForeignResidence: paymentContributor?.??? [NOT IN LS]
      // foreignCountry: paymentContributor?.??? [NOT IN LS]
      // [Bank/Payment]
      isPolicyHolder: true, // TODO check the contributor comment above; NOW it is true although it may not be like this
      iban: paymentContributorAccount?.iban,
      bankName: paymentContributorAccount?.bankName,
      bic: paymentContributorAccount?.bicNumber,
      hasPaymentAuthorization: paymentContributor.bankDetails?.hasPaymentAuthorization
    };

    return (
      <div className={classNames(styles.customer, 'mb-[24px]')}>
        <div className="flex justify-between">
          <div className="mb-[24px]">
            <div className={styles.user}>
              <Body small bold>
                {paymentContributor.lastname}, {paymentContributor.firstname}
              </Body>
            </div>
            <div>
              <Label className="text-text60">{t('contributor')}</Label>
            </div>
          </div>
        </div>
        <Accordion header={<Label bold>{t('details')}</Label>}>
          <div className="flex justify-between">
            <PaymentContributorDetail
              key={paymentContributor.firstname}
              {...mappedPaymentContributor}
            />
          </div>
        </Accordion>
      </div>
    );
  };

  return (
    <WizardSlot.Main>
      <DownloadOfferDialog
        isOpen={isDialogOpen}
        hasConsent={hasConsent}
        onDownload={downloadFile}
        onDownloadDisclaimerDocument={() =>
          downloadDisclaimerDocument(state.model.applicationStart)
        }
        onConsentClick={handleConsentClick}
        onClose={() => {
          setIsDialogOpen(false);
        }}
      />
      <div className="m:mx-[80px] pb-[32px] pt-[16px]">
        <div className="mx-[16px] m:mx-[0px]">
          <Heading level={1}>{t('summaryHeader')}</Heading>
        </div>
        <Form state={state} dispatch={dispatch}>
          <div>
            {renderInsuredPersons()}
            {renderPolicyHolder()}
            {renderPaymentContributor()}
          </div>

          <div className="p-[32px] bg-white">
            <div className="mb-[16px]">
              <Body bold>{t('dataProtectionHeader')}</Body>
            </div>
            <div className={'bg-lightGray p-[24px] mb-[40px]'}>
              <span>
                <Checkbox
                  id={EVENT_IDS.DATA_PROTECTION_CHECKBOX}
                  componentKey={partnerField(
                    holderId,
                    'applicationInformation.acceptsDataProtection'
                  )}
                  description={
                    <Body small>
                      <Trans
                        i18nKey={'summaryPage:dataProtectionDescription'}
                        components={[
                          <a
                            onClick={sendMail}
                            className="m-px text-logo-500 cursor-pointer inline underline"
                          />,
                          <a
                            onClick={downloadDisclaimer}
                            className="m-px text-logo-500 cursor-pointer inline underline"
                          />
                        ]}
                      />
                    </Body>
                  }
                />
              </span>
            </div>

            <div className="mb-[16px]">
              <Body bold>{t('consentToAdvice')}</Body>
            </div>
            <div className={'bg-lightGray p-[24px] mb-[40px]'}>
              <span>
                <Checkbox
                  id={EVENT_IDS.WAIVE_ADVICE_CHECKBOX}
                  componentKey={partnerField(
                    holderId,
                    'applicationInformation.acceptsToWaiveAdvice'
                  )}
                  description={<Body small>{t('consentToAdviceDescription')}</Body>}
                />
              </span>
            </div>

            <div className="mb-[16px]">
              <Body bold>{t('contactInformation')}</Body>
            </div>

            <div className="mb-[6px]">
              <Label className={'text-lightText'}>{t('documentInformation')}</Label>
            </div>

            <Body small>{t('contractInformationListHeader')}</Body>
            <Body small>
              <ul className="mt-[8px] mb-[24px]">
                <li>{t('contractListFirst')}</li>
                <li>{t('contractListSecond')}</li>
                <li>{t('contractListThird')}</li>
                <li>{t('contractListFourth')}</li>
                <li>{t('contractListFifth')}</li>
              </ul>
            </Body>

            <div className="mb-[24px]">
              <AccompaniedDocumentsDownload onClick={() => setIsDialogOpen(true)} />
            </div>

            <div className={'bg-lightGray p-[24px] mb-[24px]'}>
              <span>
                <Checkbox
                  id={EVENT_IDS.CONTRACT_INFORMATION_CHECKBOX}
                  componentKey={partnerField(
                    holderId,
                    'applicationInformation.acceptsContractInformation'
                  )}
                  description={<Body small>{t('contractualInformationConfirmation')}</Body>}
                />
              </span>
            </div>

            <div className="mb-[24px]">
              <Body small>{t('contractualInformationInfo')}</Body>
            </div>

            <div className={'bg-lightGray p-[24px] mb-[40px]'}>
              <span>
                <Checkbox
                  id={EVENT_IDS.DATA_PROCESSING_CHECKBOX}
                  componentKey={partnerField(
                    holderId,
                    'applicationInformation.acceptsDataCollection'
                  )}
                  description={<Body small>{t('collectionInformationConsent')}</Body>}
                />
              </span>
            </div>

            <div className="mb-[16px]">
              <Body bold>{t('dataUseForAdds')}</Body>
            </div>

            <div className={'p-[24px] bg-lightGray'}>
              <div>
                <span>
                  <Checkbox
                    id={EVENT_IDS.PROMOTIONAL_PURPOSES_CHECKBOX}
                    componentKey={partnerField(
                      holderId,
                      'applicationInformation.acceptsPromotionalPurposes'
                    )}
                    description={
                      <div>
                        <div className="mb-[16px]">
                          <Body small>{t('dataUseLabel')}</Body>
                        </div>
                        <div className="mb-[16px]">
                          <Body small>
                            <Markdown>{t('dataUseLabelBy')}</Markdown>
                          </Body>
                        </div>
                        <div className="flex m-t-[16px]">
                          <div className="flex items-center space-x-2 mr-[32px]">
                            <div>
                              <Checkbox
                                id={EVENT_IDS.PHONE_NUMBER_CHECKBOX}
                                componentKey={partnerField(
                                  holderId,
                                  'applicationInformation.acceptsPromotionalPurposesPhone'
                                )}
                              />
                            </div>
                            <div>
                              <Body small>{t('phone')}</Body>
                            </div>
                          </div>
                          <div className="flex items-center space-x-2">
                            <div>
                              <Checkbox
                                id={EVENT_IDS.EMAIL_CHECKBOX}
                                componentKey={partnerField(
                                  holderId,
                                  'applicationInformation.acceptsPromotionalPurposesEmail'
                                )}
                              />
                            </div>
                            <div>
                              <Body small>{t('mail')}</Body>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  />
                </span>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <NavigationSection />
      <LegalFooter />
    </WizardSlot.Main>
  );
};
