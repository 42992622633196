import React, { Suspense } from 'react';
import { Loader } from 'lkh-portal-ui-library';
import { ThemeProvider, createTheme } from 'lkh-portal-ui-library';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigContextProvider } from 'contexts/ConfigContext/ConfigContext';
import { DigitalOrderPage } from 'pages/DigitalOrderPage';
import { WizardRoutesEnum } from 'utils/actions';
import { DoneStep } from 'pages/DigitalOrderPage/subpages/DoneStep';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false
    }
  }
});

const theme = createTheme({
  palette: {
    primary: { main: '#10c663' }
  },
  components: {
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          height: 'inherit'
        }
      }
    }
  }
});

function App() {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<Loader />}>
          <QueryClientProvider client={client}>
            <ConfigContextProvider>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<DigitalOrderPage />} />
                  <Route path={`${WizardRoutesEnum.Done}/:applicationId`} element={<DoneStep />} />
                </Routes>
              </BrowserRouter>
            </ConfigContextProvider>
          </QueryClientProvider>
        </Suspense>
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default App;
