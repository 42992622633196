import { useHealthInsuranceContext } from 'contexts/HealthInsuranceContext';
import { useTranslation, Trans } from 'react-i18next';
import { downloadData, downloadFormByReferenceId } from 'utils/fetchDocument';

const formatHealthDisclaimer = (): JSX.Element => {
  const { t } = useTranslation('wizardHealth');
  const { state } = useHealthInsuranceContext();
  const { applicationStart } = state.model;

  async function handleDownload(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    const response = await downloadFormByReferenceId('K06_31', applicationStart || '');
    downloadData(response.data, 'Mitteilung über die Folgen einer Anzeigepflichtverletzung.pdf');
  }
  return (
    <div>
      <b>{t('section.legal.title')}</b>
      <br />
      <br />
      <b>{t('section.legal.boldText')}</b>
      <Trans
        i18nKey="wizardHealth:section.legal.text"
        components={[
          <a
            onClick={handleDownload}
            className="m-px text-logo-500 cursor-pointer inline underline"
          />
        ]}
      />
    </div>
  );
};

export { formatHealthDisclaimer };
